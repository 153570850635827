import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import axios from "axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Registra los elementos que vas a utilizar
ChartJS.register(ArcElement, Tooltip, Legend);

const PorProcesar = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [isLoading, setIsLoading] = useState(true); // Estado para manejar el estado de carga
  const [error, setError] = useState(null); // Estado para manejar errores

  // Función para obtener datos del backend
  const fetchData = async () => {
    try {
      const response = await axios.get("/KPI/EnProceso", {
        withCredentials: true,
      });
      //console.log("Data fetched:", response.data); // Muestra la data en consola
      const data = response.data;

      const labels = data.map((item) => item.servicio);
      const values = data.map((item) => item.cantidad);

      setChartData({
        labels: labels,
        datasets: [
          {
            label: "Servicios en proceso",
            data: values,
            backgroundColor: [
              "#FB8C00", // Color 1
              "#673AB7", // Color 2
              "#5C6BC0", // Color 3
              "#EC407A", // Color 4
              "#9966FF", // Color 5
            ],
          },
        ],
      });
    } catch (error) {
      //console.error("Error fetching data:", error);
      setError("Error al cargar los datos."); // Actualiza el estado de error
    } finally {
      setIsLoading(false); // Cambiar el estado de carga a false
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Calcular si no hay datos para mostrar el mensaje
  const totalCantidad =
    chartData.datasets.length > 0
      ? chartData.datasets[0].data.reduce((acc, val) => acc + val, 0)
      : 0;

  return (
    <Card className="chart-card h-100" elevation={3}>
      <CardContent>
        <Typography variant="h6" align="center">
          Servicios en Proceso
        </Typography>
        {isLoading ? ( // Muestra el indicador de carga si está cargando
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : error ? ( // Muestra el mensaje de error si ocurre un error
          <Typography color="error" align="center">
            {error}
          </Typography>
        ) : totalCantidad === 0 ? ( // Verifica si no hay datos
          <Typography
            variant="h5"
            align="center"
            color="error"
            style={{ margin: "20px 0", fontWeight: "bold" }}
          >
            No hay servicios en proceso
          </Typography>
        ) : (
          <div style={{ height: "300px" }}>
            <Pie
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PorProcesar;
