import React, { useState, useEffect } from "react";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";

const SelectServicios = ({
  required,
  onChange,
  selectedValues = [],
  register,
}) => {
  const [servicios, setServicios] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServicios = async () => {
      try {
        const response = await axios.get("/usuarios/Servicios");
        if (response.status !== 200) {
          throw new Error("Error al obtener los servicios");
        }
        const serviciosData = response.data
          .filter((servicio) => servicio.Nombre !== "Prospecto")
          .map((servicio) => ({
            id: servicio.ServicioID,
            nombre: servicio.Nombre,
          }));
        setServicios(serviciosData);
        setIsLoading(false);
      } catch (error) {
        //console.error("Hubo un error al cargar los servicios:", error);
        setError("Error al cargar los servicios");
        setIsLoading(false);
      }
    };

    fetchServicios();
  }, []);

  useEffect(() => {
    //console.log("Valores seleccionados:", selectedValues);
  }, [selectedValues]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
  
    // Check if "Gerente" (service.id 5) or related service (service.id 7) is selected
    const isGerenteSelected = value.includes(5) || value.includes(7); 
  
    // If "Gerente" or related service is selected, keep only these IDs
    const newSelectedValues = isGerenteSelected
      ? value.filter((id) => id === 5 || id === 7)
      : value;
  
    // Call onChange with the updated selected values, if provided
    if (onChange) {
      onChange(newSelectedValues);
    }
  };
  

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <FormControl fullWidth variant="outlined" margin="normal">
      <InputLabel id="select-servicios-label">Servicios</InputLabel>
      <Select
        labelId="select-servicios-label"
        id="selectServicios"
        multiple
        value={selectedValues}
        onChange={handleChange}
        inputProps={{
          ...register("servicios"),
        }}
        renderValue={(selected) =>
          selected
            .map((id) => {
              const servicio = servicios.find((serv) => serv.id === id);
              return servicio ? servicio.nombre : `Servicio ${id}`;
            })
            .join(", ")
        }
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 224,
              width: 250,
              backgroundColor: "white",
            },
          },
        }}
        sx={{
          backgroundColor: "white",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "blue",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "blue",
          },
          ".MuiSvgIcon-root": {
            color: "black",
          },
        }}
      >
        {servicios.map((servicio) => (
          <MenuItem key={servicio.id} value={servicio.id}>
            <Checkbox checked={selectedValues.includes(servicio.id)} />
            <ListItemText primary={servicio.nombre} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectServicios;
