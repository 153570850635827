import React, { useState, useEffect } from "react";
import { 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Button, 
  Box, 
  IconButton, 
  Collapse, 
  Tooltip 
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";

const TableFilter = ({ onApplyFilter }) => {
  const [filterOptions, setFilterOptions] = useState({
    Oficina: [],
    Servicio: [],
    Status: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    Oficina: "",
    Servicio: "",
    Status: "",
  });
  const [showFilters, setShowFilters] = useState(false);

  // Llamada a la API para obtener las opciones de los filtros
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const [oficinaResponse, servicioResponse, statusResponse] = await Promise.all([
          axios.get("/select/oficinas"), 
          axios.get("/select/Servicios"),
          axios.get("/select/status"),
        ]);

        console.log(oficinaResponse, servicioResponse, statusResponse)
        setFilterOptions({
          Oficina: oficinaResponse.data,
          Servicio: servicioResponse.data,
          Status: statusResponse.data,
        });
      } catch (error) {
        console.error("Error al obtener opciones de filtro:", error);
      }
    };

    fetchFilterOptions();
  }, []); // Solo se ejecuta una vez al cargar el componente

  const handleFilterChange = (column, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value,
    }));
  };

  const handleApplyFilter = () => {
    onApplyFilter(selectedFilters);
    console.log("Filtros aplicados:", selectedFilters);
  };

  const handleClearFilters = () => {
    const clearedFilters = {
      Oficina: "",
      Servicio: "",
      Status: "",
    };
    setSelectedFilters(clearedFilters);
    onApplyFilter(clearedFilters); // Aplicar filtros vacíos
    console.log("Filtros limpiados:", clearedFilters);
  };

  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  return (
    <Box>
      {/* Botón para mostrar/ocultar filtros con tooltip */}
      <Tooltip title="Mostrar/Ocultar Filtros" arrow>
        <IconButton color="primary" onClick={toggleFilters}>
          <FilterAltIcon />
        </IconButton>
      </Tooltip>

      {/* Campos de filtro */}
      <Collapse in={showFilters}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            marginBottom: 2,
            flexWrap: "wrap",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          {Object.keys(filterOptions).map((column) => (
            <FormControl key={column} variant="outlined" size="small" sx={{ minWidth: 150 }}>
              <InputLabel>{column}</InputLabel>
              <Select
                value={selectedFilters[column]}
                onChange={(e) => handleFilterChange(column, e.target.value)}
                label={column}
              >
                <MenuItem value="">Todos</MenuItem>
                {filterOptions[column].map((option) => (
                  <MenuItem
                    key={option.ID}
                    value={column === "Status" ? option.ID : option.Nombre} // Si es Status, usa ID
                  >
                    {option.Nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}

          {/* Botón para aplicar filtros */}
          <Button variant="contained" color="primary" onClick={handleApplyFilter}>
            Aplicar Filtros
          </Button>

          {/* Botón para limpiar filtros con tooltip */}
          <Tooltip title="Limpiar Filtros" arrow>
            <IconButton color="secondary" onClick={handleClearFilters}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Collapse>
    </Box>
  );
};

export default TableFilter;
