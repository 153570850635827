import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, Paper, Typography, Grid, Chip, CircularProgress, 
  TextField, Select, MenuItem, Button, InputLabel, FormControl, 
  Box
} from '@mui/material';
import Menu from "../../src/components/Menu";  // Asegúrate de que la ruta sea correcta

const UsuariosActivos = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [nombre, setNombre] = useState('');
  const [oficina, setOficina] = useState('');
  const [loginStatus, setLoginStatus] = useState('');
  
  const [oficinas, setOficinas] = useState([]);

  // Obtener usuarios logueados desde la API
  useEffect(() => {
    const fetchData = () => {
      axios.get('/select/logeados')  // Asegúrate de tener la ruta correcta para tu API
        .then(response => {
          const data = response.data;
          setUsers(data);  // Datos de los usuarios logueados
          setFilteredUsers(data);  // Inicialmente mostrar todos los usuarios
          
          // Obtener oficinas únicas
          const uniqueOficinas = [...new Set(data.map(user => user.Oficina?.Nombre))];
          setOficinas(uniqueOficinas);
        })
        .catch(error => {
          console.error("Error al obtener los usuarios", error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
    // Ejecutar inmediatamente al montar el componente
    fetchData();
  
    // Configurar intervalo para ejecutar cada minuto
    const interval = setInterval(fetchData, 60000);
  
    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    console.log("usuarios", users);
  }, [users])
  

  const handleFilter = () => {
    let filteredData = users;

    if (nombre) {
      filteredData = filteredData.filter(user => 
        `${user.Nombres} ${user.ApellidoPaterno} ${user.ApellidoMaterno}`
          .toLowerCase()
          .includes(nombre.toLowerCase())
      );
    }

    if (oficina) {
      filteredData = filteredData.filter(user => user.Oficina?.Nombre === oficina);
    }

    if (loginStatus) {
      filteredData = filteredData.filter(user => user.LoginStatus === parseInt(loginStatus));
    }

    setFilteredUsers(filteredData);
  };

  const handleClearFilter = () => {
    setNombre('');
    setOficina('');
    setLoginStatus('');
    setFilteredUsers(users);
  };

  return (
    <div>
      <header>
        <Menu />  {/* Menú en el encabezado */}
      </header>

      <div className="bg-beige container-wrapper">
        <Grid container spacing={3} style={{ padding: '20px' }}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom align="center" color="primary">
              Usuarios Logueados
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 3 }} elevation={3}>
              {/* Filtros */}
              <Box sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label="Nombre de Usuario"
                      variant="outlined"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Oficina</InputLabel>
                      <Select
                        value={oficina}
                        onChange={(e) => setOficina(e.target.value)}
                        label="Oficina"
                      >
                        <MenuItem value="">
                          <em>Seleccione una Oficina</em>
                        </MenuItem>
                        {oficinas.map((oficinaName, index) => (
                          <MenuItem key={index} value={oficinaName}>
                            {oficinaName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Estado</InputLabel>
                      <Select
                        value={loginStatus}
                        onChange={(e) => setLoginStatus(e.target.value)}
                        label="Estado"
                      >
                        <MenuItem value="">
                          <em>Seleccione Estado</em>
                        </MenuItem>
                        <MenuItem value="1">Activo</MenuItem>
                        <MenuItem value="0">Inactivo</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={handleFilter}
                        >
                          Aplicar
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="outlined"
                          color="secondary"
                          onClick={handleClearFilter}
                        >
                          Limpiar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              {/* Tabla */}
              {loading ? (
                <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '200px' }}>
                  <CircularProgress />
                </Grid>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Nombre de Usuario</TableCell>
                        <TableCell align="center">Oficina</TableCell>
                        <TableCell align="center">Activo</TableCell>
                        <TableCell align="center">Último Ingreso</TableCell>
                        <TableCell align="center">Última Salida</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredUsers.map((user) => (
                        <TableRow key={user.AsesorID}>
                          <TableCell align="center">{user.Nombres} {user.ApellidoPaterno} {user.ApellidoMaterno}</TableCell>
                          <TableCell align="center">{user.Oficina ? user.Oficina.Nombre : 'N/A'}</TableCell>
                          <TableCell align="center">
                            <Chip label={user.LoginStatus === 1 ? "Activo" : "Inactivo"} color={user.LoginStatus === 1 ? "success" : "default"} />
                          </TableCell>
                          <TableCell align="center">
                            {user.HistoricoIngresos?.[0]?.HoraLogIn 
                              ? new Date(user.HistoricoIngresos[0].HoraLogIn).toLocaleString() 
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="center">
                            {user.HistoricoIngresos?.[0]?.HoraLogOut 
                              ? new Date(user.HistoricoIngresos[0].HoraLogOut).toLocaleString() 
                              : 'N/A'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default UsuariosActivos;
