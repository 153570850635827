import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const TextInput = ({
  Gerente,
  id,
  label,
  value,
  color,
  register,
  required,
  pattern,
  maxLength,
  minLength,
  errors,
  validation,
  type = "text", // Tipo de input, por defecto es 'text'
}) => {
  // Función para convertir el texto ingresado a mayúsculas
  const handleInput = (e) => {
    if (type !== "password" && id !== "Contrasena" && id !== "ContrasenaCuentaInfonavit") {
      e.target.value = e.target.value.toUpperCase();
    }
  };

  useEffect(() => {
    //console.log(`Pattern para ${label}`, validation);
  }, [validation, label]);

  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <input
        style={{ background: color }}
        readOnly={Gerente}
        type={type}
        id={id}
        value={value}
        required={required}
        className="form-control"
        maxLength={type !== "password" ? maxLength : undefined}
        onInput={handleInput}
        {...register(id, {
          pattern: validation && {
            value: validation.pattern.value,
            message: validation.pattern.message,
          },
          maxLength: type !== "password" &&
            maxLength && {
              value: maxLength,
              message: `${label} no puede exceder los ${maxLength} caracteres.`,
            },
          minLength: minLength && {
            value: minLength,
            message: `${label} debe tener al menos ${minLength} caracteres.`,
          },
          required: required && {
            value: true,
            message: `${label} es obligatorio.`,
          },
        })}
      />
      {errors[id] && (
        <div style={{ display: "flex", alignItems: "center", color: "white" }}>
          <IconButton style={{ padding: 0, marginRight: 5 }}>
            <ErrorOutlineIcon style={{ color: "red" }} />
          </IconButton>
          <span>{errors[id].message}</span>
        </div>
      )}
    </div>
  );
};

export default TextInput;
