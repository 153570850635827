import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import axios from "axios";

const ProtectedRoute = ({ element: Component }) => {
  const { token } = useParams(); // Obtén el token de los parámetros de la ruta
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  //console.log(token)
  useEffect(() => {
    const checkAuth = async () => {
      try {
        // Envía el token al backend para verificarlo
        const response = await axios.get("/login/check-auth", {
          headers: {
            Authorization: `Bearer ${token}`, // O el método que estés usando para verificar el token
          },
          withCredentials: true,
        });
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        //console.error('Error en la autenticación:', error);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, [token]); // Asegúrate de incluir el token en la lista de dependencias

  if (isAuthenticated === null) {
    // Mostrar un cargando mientras se verifica la autenticación
    return <div>Cargando...</div>;
  }

  if (isAuthenticated === false) {
    // Si no está autenticado, redirigir al login
    return <Navigate to="/" />;
  }

  // Renderizar el componente protegido
  return <Component />;
};

export default ProtectedRoute;
