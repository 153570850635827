import React from "react";
import { Button } from "@mui/material";
import axios from "axios";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const ExportButton = ({ tableData, style }) => {
  const handleExport = async () => {
    //console.log(tableData)
    try {
      const response = await axios.post("/Reportes/Exportar", tableData, {
        responseType: "blob", // Para recibir el archivo como un blob
      });

      // Crear un enlace para descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "reporte.xlsx"); // Nombre del archivo
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      //console.error('Error al exportar:', error);
    }
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleExport}
      style={style}
    >
      <FileDownloadIcon /> &nbsp; Exportar Excel
    </Button>
  );
};

export default ExportButton;
