import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  ModalDialog,
} from "@mui/joy";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import axios from "axios";

export default function Confirmacion({ open, onClose, modalData }) {
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("green");

  useEffect(() => {
    if (open && modalData) {
      //console.log("Modal abierto con datos:", modalData);
    }
  }, [open, modalData]);

  const handleConfirm = async () => {
    try {
      // Realizar la solicitud POST para eliminar el usuario
      const response = await axios.post(
        `/usuarios/EliminarUsuario/${modalData.AsesorID}`,
        {
          StatusID: 2, // Aquí podrías pasar otros datos necesarios para la eliminación
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true, // Envía las credenciales (cookies) en la solicitud
        }
      );

      if (response.status === 200 || response.status === 201) {
        showMessage(
          <span>
            <CheckCircleIcon />{" "}
            {response.data.message || "Usuario eliminado correctamente"}
          </span>,
          "green"
        );
        setTimeout(() => {
          onClose(); // Cierra el modal después de 3 segundos
        }, 3000);
      } else {
        showMessage(
          <span>
            <WarningIcon />{" "}
            {response.data.error || "Error al eliminar el usuario"}
          </span>,
          "red"
        );
      }
    } catch (error) {
      //console.error("Error al enviar datos:", error);
      showMessage(
        <span>
          <WarningIcon />{" "}
          {error.response?.data?.error || "Error al eliminar el usuario"}
        </span>,
        "red"
      );
    }
  };

  const showMessage = (msg, color = "green") => {
    setMessage(msg);
    setMessageColor(color);
    setTimeout(() => {
      setMessage("");
    }, 3000); // Mostrar el mensaje durante 3 segundos
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          <WarningRoundedIcon />
          Confirmación
        </DialogTitle>
        <Divider />
        <DialogContent>
          ¿Estás seguro de que quieres dar de baja al usuario?
        </DialogContent>
        <DialogContent>
          {message && <span style={{ color: messageColor }}>{message}</span>}
        </DialogContent>
        <DialogActions>
          <Button variant="solid" color="danger" onClick={handleConfirm}>
            Confirmar
          </Button>
          <Button variant="plain" color="neutral" onClick={onClose}>
            Cancelar
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
}
