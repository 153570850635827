import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  IconButton,
  Tooltip,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
  ArrowBackIos as ArrowBackIosIcon,
} from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Menu from "../Menu";

const DocumentManager = () => {
  const { ClienteID, ServicioID } = useParams();
  const [documents, setDocuments] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedDocument, setSelectedDocument] = useState("");
  const [selectedDocumentID, setSelectedDocumentID] = useState(null);
  const [submitError, setSubmitError] = useState(false);
  const [file, setFile] = useState(null);
  const [, setFileName] = useState(""); // Estado nuevo para el nombre del archivo
  const [, setLoading] = useState(false); // Estado de carga
  const [fileInputKey, setFileInputKey] = useState(0); // Clave única para el input de archivo


  const fetchDocuments = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/services/Documentos/${ClienteID}/${ServicioID}`
      );
      setDocuments(response.data);
    } catch (error) {
      //console.error('Error al obtener documentos:', error);
      setMessage("Error al obtener documentos.");
    } finally {
      setLoading(false);
    }
  }, [ClienteID, ServicioID]);

  const fetchUploadedFiles = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/services/Subidos/${ClienteID}/${ServicioID}`
      );
      setUploadedFiles(response.data);
      setSubmitError(false);
    } catch (error) {
      //console.error('Error al obtener archivos subidos:', error);
      setMessage("Error al obtener archivos subidos.");
      setSubmitError(true);
    } finally {
      setLoading(false);
    }
  }, [ClienteID, ServicioID]);

  useEffect(() => {
    fetchDocuments();
    fetchUploadedFiles();
  }, [fetchDocuments, fetchUploadedFiles]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : ""); // Establecer nombre del archivo
  };

  const handleDocumentChange = (event) => {
    const selectedDoc = event.target.value;
    setSelectedDocument(selectedDoc);
    const doc = documents.find((doc) => doc.Nombre === selectedDoc);
    setSelectedDocumentID(doc ? doc.ArticuloID : null);
  };

  const handleSave = async () => {
    if (!file || !selectedDocumentID) {
      //console.error('No se ha seleccionado ningún archivo o documento');
      setMessage("Por favor, seleccione un archivo y un documento para subir.");
      return;
    }

    const formData = new FormData();
    formData.append("document", file);
    formData.append("documentName", selectedDocument);
    formData.append("ArticuloID", selectedDocumentID);

    try {
      const response = await axios.post(
        `/services/SubirDocumento/${ClienteID}/${ServicioID}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setMessage(response.data.Message || "Archivo subido exitosamente");
      setFile(null); // Limpiar archivo después de subir
      setFileName(""); // Limpiar nombre de archivo
      setSelectedDocument(""); // Limpiar documento seleccionado
      setSelectedDocumentID(null); // Limpiar ID del documento seleccionado

      // Cambiar la clave del input de archivo para forzar la recarga
      setFileInputKey((prevKey) => prevKey + 1);

      // Obtener las listas actualizadas de documentos y archivos subidos
      await fetchUploadedFiles();
      await fetchDocuments();

      setTimeout(() => setMessage(""), 3000);
    } catch (error) {
      //console.error('Error al subir archivo:', error);
      setMessage("Error al subir archivo.");
      setSubmitError(true);
    }
  };

  const handleDelete = async (file) => {
    try {
      await axios.post(
        `/services/EliminarDocumento/${ClienteID}/${ServicioID}`,
        { data: { file } }
      );
      setMessage("Archivo eliminado exitosamente");

      // Obtener la lista actualizada de archivos subidos
      await fetchDocuments();
      await fetchUploadedFiles(); // Refrescar la lista de archivos subidos
      setTimeout(() => setMessage(""), 3000);
    } catch (error) {
      //console.error('Error al eliminar archivo:', error);
      setMessage("Error al eliminar archivo.");
    }
  };

  const handleDownload = async (file) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const fileUrl = `${baseUrl}${file.Ruta}`;

    try {
      const response = await axios.get(fileUrl, {
        responseType: "blob",
      });
      const blob = response.data;
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${file.Catalogo.Nombre}_${ClienteID || "archivo"}`;
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {
      //console.error('Error al descargar archivo:', error);
      setMessage("Error al descargar archivo.");
    }
  };

  const handleView = (file) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const fileUrl = `${baseUrl}${file.Ruta}`;
    window.open(fileUrl, "_blank");
  };

  const severity = submitError ? "error" : message ? "success" : "";

  return (
    <div>
      <Helmet>
        <title>Subir Documentos</title>
      </Helmet>
      <header>
        <Menu />
      </header>
      <Box className="container-wrapper">
        <Box className="bg-beige">
          <Box className="container documentos-container">
            <h1>
              <Tooltip title="Regresar">
                <Link
                  to={`/NuevoCliente/${ClienteID}/${ServicioID}`}
                  className="back-link"
                >
                  <ArrowBackIosIcon />
                </Link>
              </Tooltip>
              Subir Documentos
            </h1>
            <Box className="form-container">
              <Box
                className="form-group"
                display="flex"
                alignItems="center"
                mb={2}
              >
                <FormControl fullWidth className="form-control">
                  <InputLabel id="document-select-label">
                    Seleccionar un documento
                  </InputLabel>
                  <Select
                    labelId="document-select-label"
                    value={selectedDocument || ""}
                    onChange={handleDocumentChange}
                  >
                    <MenuItem value="" disabled>
                      <em>Seleccionar un documento</em>
                    </MenuItem>
                    {documents.map((doc) => (
                      <MenuItem key={doc.ArticuloID} value={doc.Nombre}>
                        {doc.Nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <input
                  type="file"
                  onChange={handleFileChange}
                  key={fileInputKey} // Clave única para forzar recarga
                  style={{ marginLeft: "10px" }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  startIcon={<UploadIcon />}
                  sx={{
                    ml: 3,
                    bgcolor: "primary.main", // Color de fondo
                    color: "white", // Color del texto
                    padding: "10px 20px", // Espaciado interno
                    borderRadius: "8px", // Esquinas redondeadas
                    boxShadow: 2, // Sombra
                    "&:hover": {
                      bgcolor: "primary.dark", // Color de fondo al pasar el mouse
                      boxShadow: 4, // Sombra más profunda al pasar el mouse
                    },
                    transition: "background-color 0.3s, box-shadow 0.3s", // Transiciones suaves
                  }}
                >
                  Guardar
                </Button>
              </Box>
              {message && (
                <div className={`alert alert-${severity}`} role="alert">
                  {message}
                </div>
              )}
              <Box
                component="ul"
                className="file-list"
                sx={{ listStyleType: "none", p: 0 }}
              >
                {uploadedFiles.map((file) => (
                  <Box
                    component="li"
                    key={file.Ruta}
                    className="file-item"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 1,
                      p: 1,
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                    }}
                  >
                    <Checkbox sx={{ mr: 1 }} />
                    <Typography variant="body1" sx={{ flex: 1 }}>
                      {file.Catalogo.Nombre}
                    </Typography>
                    <Tooltip title="Ver">
                      <IconButton
                        onClick={() => handleView(file)}
                        sx={{ mr: 1 }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Descargar">
                      <IconButton
                        onClick={() => handleDownload(file)}
                        sx={{ mr: 1 }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                      <IconButton
                        onClick={() => handleDelete(file)}
                        sx={{ color: "red" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default DocumentManager;
