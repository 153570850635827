import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import LogoMenu from "../LogoMenu.png";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

const Menu = () => {
  const [permissions, setPermissions] = useState([]);
  const [gerente, setGerente] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axios.post(
          "/usuarios/getPermissions",
          {},
          { withCredentials: true }
        );
        if (response.data.success) {
          setPermissions(response.data.permissions);
        }
      } catch (error) {
        //console.error(error);
      }
    };
    fetchPermissions();
  }, []);

  useEffect(() => {
    const fetchGerente = async () => {
      try {
        const response = await axios.get("/select/Gerente", {
          withCredentials: true,
        });
        //console.log("Respuesta menu:", response);
        if (response.data.hasGerenteService) {
          setGerente(response.data.hasGerenteService);
        }
      } catch (error) {
        //console.error(error);
      }
    };
    fetchGerente();
  }, []);

  const handleLogout = async () => {
    try {
      await axios.post("/login/logout", {}, { withCredentials: true });
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light px-3">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/Dashboard">
          <img
            src={LogoMenu}
            className="img-fluid"
            alt="Logo"
            style={{ width: "100px", height: "50px" }}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className="nav-link active"
                aria-current="page"
                to="/Clientes"
              >
                Clientes
              </Link>
            </li>
            {permissions.includes("1") && (
              <li className="nav-item">
                <Link className="nav-link" to="/Usuarios">
                  Usuarios
                </Link>
              </li>
            )}

            {permissions.includes("1") && (
              <li className="nav-item">
                <Link className="nav-link" to="/UsuariosActivos">
                  Actividad
                </Link>
              </li>
            )}
          </ul>
          <div className="d-flex align-items-center ms-auto">
            <h5 className="mb-0 me-3">
              {permissions.includes("1")
                ? "Administrador"
                : gerente === 1
                ? "Gerente"
                : gerente === 2
                ? "Administrativo"
                : "Asesor"}
            </h5>

            <button className="btn btn-outline-danger" onClick={handleLogout}>
              Cerrar sesión
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
