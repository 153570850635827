import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import axios from "axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Registra los elementos que vas a utilizar
ChartJS.register(ArcElement, Tooltip, Legend);

const Detenidos = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get("/KPI/Detenidos", {
        withCredentials: true,
      });
      //console.log("Data fetched:", response.data);
      const data = response.data;

      const labels = data.map((item) => item.servicio);
      const values = data.map((item) => item.cantidad);

      setChartData({
        labels: labels,
        datasets: [
          {
            label: "Servicios detenidos",
            data: values,
            backgroundColor: [
              "#FB8C00",
              "#673AB7",
              "#5C6BC0",
              "#EC407A",
              "#9966FF",
            ],
          },
        ],
      });
    } catch (error) {
      //console.error("Error fetching data:", error);
      setError("Error al cargar los datos.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    //console.log("Chardata", chartData.datasets);
  }, [chartData]);

  // Sumar las cantidades
  const totalCantidad =
    chartData.datasets.length > 0
      ? chartData.datasets[0].data.reduce((acc, val) => acc + val, 0)
      : 0;

  return (
    <Card className="chart-card h-100" elevation={3}>
      <CardContent>
        <Typography variant="h6" align="center">
          Servicios detenidos
        </Typography>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" align="center">
            {error}
          </Typography>
        ) : totalCantidad === 0 ? ( // Verifica si la suma de cantidades es 0
          <Typography
            variant="h5"
            align="center"
            color="error"
            style={{ margin: "20px 0", fontWeight: "bold" }}
          >
            No hay detenidos
          </Typography>
        ) : (
          <div style={{ height: "300px" }}>
            <Pie
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default Detenidos;
