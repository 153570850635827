import React from "react";

const Campos = ({
  htmlFor,
  label,
  register,
  required,
  pattern,
  messages,
  errors,
  tipo, // Tipo de campo (text, number, email, etc.)
  maxLength,
  minLength,
  readOnly,
  defaultValue,
  rows,
  value, // Número de filas para campos multilinea
}) => {
  const handleKeyPress = (event) => {
    // Si el campo es de teléfono, bloquear letras y caracteres especiales
    if (htmlFor === "telefono") {
      const keyCode = event.which || event.keyCode;
      const isValidKey =
        (keyCode >= 48 && keyCode <= 57) ||
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 46;

      if (!isValidKey) {
        event.preventDefault();
      }
    }
  };

  const renderField = () => {
    if (rows && rows > 1) {
      return (
        <textarea
          className="form-control"
          id={htmlFor}
          {...register(htmlFor, {
            required: required ? messages?.req : false,
            maxLength: maxLength && {
              value: maxLength,
              message: `El campo ${label} debe tener ${maxLength} caracteres o menos.`,
            },
            minLength: minLength && {
              value: minLength,
              message: `El campo ${label} debe tener al menos ${minLength} caracteres.`,
            },
            pattern: pattern && {
              value: new RegExp(pattern),
              message: messages?.[htmlFor],
            },
          })}
          name={htmlFor}
          maxLength={maxLength}
          minLength={minLength}
          readOnly={readOnly}
          defaultValue={defaultValue || ""}
          rows={rows} // Usar el número de filas especificado para campos multilinea
          value={value}
        />
      );
    } else {
      return (
        <input
          type={tipo} // Usar el tipo especificado (text, number, email, etc.)
          className="form-control"
          id={htmlFor}
          {...register(htmlFor, {
            required: required ? messages?.req : false,
            maxLength: maxLength && {
              value: maxLength,
              message: `El campo ${label} debe tener ${maxLength} caracteres o menos.`,
            },
            minLength: minLength && {
              value: minLength,
              message: `El campo ${label} debe tener al menos ${minLength} caracteres.`,
            },
            pattern: pattern && {
              value: new RegExp(pattern),
              message: messages?.[htmlFor],
            },
          })}
          name={htmlFor}
          maxLength={maxLength}
          minLength={minLength}
          readOnly={readOnly}
          onKeyPress={handleKeyPress}
          defaultValue={defaultValue || ""}
        />
      );
    }
  };

  return (
    <div>
      <label htmlFor={htmlFor} className="form-label">
        {label}
        {required && ( // Agregar el asterisco rojo si el campo es requerido
          <span style={{ color: "red", marginLeft: "5px" }}>*</span>
        )}
      </label>
      {renderField()}{" "}
      {/* Llamar a la función que decide qué campo renderizar */}
      {errors[htmlFor] && (
        <span style={{ color: "red" }}>
          <i className="fas fa-exclamation-triangle"></i>{" "}
          {errors[htmlFor].message}
        </span>
      )}
    </div>
  );
};

export default Campos;
