import React, { useState, useEffect } from "react";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Box,
  CircularProgress,
  Grid, 
  Button,
  Snackbar,
  FormHelperText
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
const LocationForm = ({
  Gerente,
  initialCodigoPostal,
  initialMunicipioID,
  initialLocalidadID,
  theme,
  onLocationChange,
}) => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const [estados, setEstados] = useState([]);
  const [selectedEstado, setSelectedEstado] = useState("");
  const [codigoPostal, setCodigoPostal] = useState(initialCodigoPostal || "");
  const [localidades, setLocalidades] = useState([]);
  const [selectedLocalidad, setSelectedLocalidad] = useState("");
  const [loadingLocalidades, setLoadingLocalidades] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Fetch estados and determine the selected state
  useEffect(() => {
    const fetchEstados = async () => {
      try {
        const response = await axios.get("/Direcciones/estados");
        setEstados(response.data);
      } catch (error) {
        console.error("Error fetching estados:", error);
      }
    };

    const fetchSelectedEstado = async () => {
      if (initialCodigoPostal && initialLocalidadID) {
        try {
          const response = await axios.get(
            `/Direcciones/BuscarEstado/${initialMunicipioID}/${initialLocalidadID}/${initialCodigoPostal}`
          );
          setSelectedEstado(response.data.EstadoID);
        } catch (error) {
          console.error("Error fetching estado seleccionado:", error);
        }
      }
    };

    fetchEstados();
    fetchSelectedEstado();
  }, [initialCodigoPostal, initialLocalidadID, initialMunicipioID]);

  // Fetch localidades when estado or codigoPostal changes
  useEffect(() => {
    const fetchLocalidades = async () => {
      if (!selectedEstado || codigoPostal.length !== 5) return;

      setLoadingLocalidades(true);
      try {
        const response = await axios.get(
          `/Direcciones/localidades/${selectedEstado}/${codigoPostal}`
        );
        if (response.status === 200 || response.status === 202) {
          setLocalidades(response.data);
          if (
            response.data.some(
              (localidad) => localidad.LocalidadID === initialLocalidadID
            )
          ) {
            setSelectedLocalidad(initialLocalidadID);
            setValue("localidad", initialLocalidadID);
          } else {
            setSelectedLocalidad("");
            setValue("localidad", "");
          }
        } else {
          showErrorSnackbar(
            "No se encontró ninguna localidad para los valores proporcionados."
          );
        }
      } catch (error) {
        showErrorSnackbar("Error al buscar localidades.");
        console.error("Error fetching localidades:", error);
      } finally {
        setLoadingLocalidades(false);
      }
    };

    fetchLocalidades();
  }, [selectedEstado, codigoPostal, initialLocalidadID, setValue]);

  const handleSearchLocalidades = () => {
    if (!selectedEstado || codigoPostal.length !== 5) {
      showErrorSnackbar(
        "Por favor, selecciona un estado y un código postal válido."
      );
      return;
    }
    setLocalidades([]);
    setSelectedLocalidad("");
  };

  const showErrorSnackbar = (message) => {
    setErrorMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {/* Select for Estado */}
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth variant="filled" margin="normal" error={!!errors.estado}>
            <InputLabel sx={{ color: "black" }}>Estado *</InputLabel>
            <Controller
              name="estado"
              control={control}
              render={({ field }) => (
                <Select
                  required
                  readOnly={Gerente}
                  {...field}
                  value={selectedEstado}
                  onChange={(e) => {
                    setSelectedEstado(e.target.value);
                    setLocalidades([]);
                    setSelectedLocalidad("");
                  }}
                  sx={{
                    bgcolor: theme.palette.background.default,
                    color: "black",
                  }}
                >
                  {estados.map((estado) => (
                    <MenuItem
                      key={estado.EstadoID}
                      value={estado.EstadoID}
                      sx={{ color: "black" }}
                    >
                      {estado.Nombre}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.estado && <FormHelperText>{errors.estado.message}</FormHelperText>}
          </FormControl>
        </Grid>

        {/* TextField for Codigo Postal */}
        <Grid item xs={12} sm={3}>
          <TextField
            required
            readOnly={Gerente}
            label="Código Postal"
            {...register("codigoPostal", {
            
              maxLength: { value: 5, message: "El código postal debe tener 5 dígitos." },
            })}
            value={codigoPostal}
            onChange={(e) => setCodigoPostal(e.target.value)}
            fullWidth
            variant="filled"
            margin="normal"
            inputProps={{ maxLength: 5 }}
            sx={{
              bgcolor: theme.palette.background.default,
              color: "black",
            }}
            InputLabelProps={{ style: { color: "black" } }}
            InputProps={{ style: { color: "black" } }}
            error={!!errors.codigoPostal}
            helperText={errors.codigoPostal?.message}
          />
        </Grid>

        {/* Button for Search */}
        <Grid item xs={12} sm={3}>
          <Button
            readOnly={Gerente}
            fullWidth
            onClick={handleSearchLocalidades}
            sx={{
              bgcolor: theme.palette.secondary.main,
              color: "white",
              "&:hover": { bgcolor: theme.palette.secondary.dark },
              padding: "12px 16px",
              borderRadius: "4px",
            }}
          >
            Buscar Localidades
          </Button>
        </Grid>

        {/* Select for Localidad */}
        <Grid item xs={12} sm={3}>
          <FormControl
            fullWidth
            variant="filled"
            margin="normal"
            error={!!errors.localidad}
            disabled={loadingLocalidades || localidades.length === 0}
          >
            <InputLabel sx={{ color: "black" }}>Localidad *</InputLabel>
            {loadingLocalidades ? (
              <CircularProgress size={24} />
            ) : (
              <Controller
                name="localidad"
                control={control}
              
                render={({ field }) => (
                  <Select
                    required
                    readOnly={Gerente}
                    {...field}
                    value={selectedLocalidad || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSelectedLocalidad(value);
                      field.onChange(value);
                      onLocationChange({
                        estado: selectedEstado,
                        codigoPostal,
                        localidad: value,
                      });
                    }}
                    sx={{
                      bgcolor: theme.palette.background.default,
                      color: "black",
                    }}
                  >
                    {localidades.map((localidad) => (
                      <MenuItem
                        key={localidad.LocalidadID}
                        value={localidad.LocalidadID}
                        sx={{ color: "black" }}
                      >
                        {localidad.Nombre}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            )}
            {errors.localidad && <FormHelperText>{errors.localidad.message}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>

      {/* Snackbar for error messages */}
      <Snackbar
        open={openSnackbar}
        message={errorMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      />
    </Box>
  );
};



export default LocationForm;
