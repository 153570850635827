// NotificacionAsesorModal.js
import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";

const NotificacionAsesorModal = ({ open, onClose, onSend, clienteId, servicioId, asesorID }) => {
  const [mensaje, setMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const handleSendClick = async () => {
    const response = await onSend(clienteId, servicioId, asesorID, mensaje);

    console.log("Response: " + response)
    if (response) {
        console.log(response)
      setConfirmationMessage(response); // Establece el mensaje de confirmación desde la respuesta
      setTimeout(() => {
        setConfirmationMessage(""); // Limpia el mensaje después de 3 segundos
        onClose(); // Cierra el modal
      }, 3000);
    }
    
    setMessage(""); // Limpia el campo de mensaje
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
          Notificar a Asesor
        </Typography>
        <TextField
          fullWidth
          label="Mensaje"
          variant="outlined"
          value={mensaje}
          onChange={(e) => setMessage(e.target.value)}
          multiline
          rows={4}
          inputProps={{ maxLength: 500 }}
          margin="normal"
        />
        {confirmationMessage && (
          <Typography variant="body2" color="primary" sx={{ mt: 2 }}>
            {confirmationMessage}
          </Typography>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
          <Button variant="contained" onClick={handleSendClick}>
            Enviar
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cerrar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NotificacionAsesorModal;
