import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import axios from "axios";

const ServiciosSimple = ({ onChange, enableSelect, value, color, Letras }) => {
  const [servicios, setServicios] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchServicios = async () => {
      try {
        const response = await axios.get("/usuarios/ServiciosPorAsesor", {
          withCredentials: true,
        });
        //console.log("Servicios obtenidos:", response.data); // Muestra los datos correctamente obtenidos
        setServicios(response.data); // Almacena los datos en el estado
      } catch (error) {
        //console.error('Error fetching services:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchServicios();
  }, []);

  // Usa un useEffect separado para monitorear cambios en 'servicios'
  useEffect(() => {
    //console.log("Estado actualizado servicios:", servicios); // Verifica si 'servicios' se actualiza
  }, [servicios]);

  useEffect(() => {
    //console.log('Value changed:', value);
  }, [value]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    //console.log('Selected Value:', selectedValue);
    if (onChange) onChange(selectedValue);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <FormControl fullWidth variant="filled" margin="normal">
      <InputLabel
        style={{ color: Letras, fontSize: 20 }}
        id="select-servicios-label"
      >
        Servicios
      </InputLabel>
      <Select
        style={{ background: color, color: "#000000" }}
        labelId="select-servicios-label"
        id="selectServicio"
        value={value || ""}
        onChange={handleChange}
        label="Servicios"
        disabled={!enableSelect || Boolean(value)}
      >
        {servicios
          .filter(
            (servicio) =>
              servicio &&
              servicio.Nombre &&
              !servicio.Nombre.includes("Gerente")
          )
          .map((servicio) => (
            <MenuItem
              key={servicio.ServicioID}
              value={servicio.ServicioID}
              style={{ color: "#000000" }}
            >
              {servicio.Nombre}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ServiciosSimple;
