import React, {useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Logo2 from "../GrupoSirLogo.png"; // Asegúrate de importar tu logo correctamente
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Tooltip from "@mui/material/Tooltip"; // Importa Tooltip desde Material-UI
import Visibility from "@mui/icons-material/Visibility"; // Importa el ícono de visibilidad
import VisibilityOff from "@mui/icons-material/VisibilityOff"; // Importa el ícono de visibilidad desactivada
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Importa ícono de check verde
import CancelIcon from "@mui/icons-material/Cancel"; // Importa ícono de cruz roja

const NuevaContrasena = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Mensaje de error adicional
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar/ocultar contraseña
  const [showConfPassword, setShowConfPassword] = useState(false); // Estado para mostrar/ocultar confirmación de contraseña
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });

  const navigate = useNavigate();
  const { token } = useParams(); // Obtiene el token de la ruta

  const validatePassword = (password) => {
    const length = password.length >= 8;
    const lowercase = /[a-z]/.test(password);
    const uppercase = /[A-Z]/.test(password);
    const number = /\d/.test(password);
    const specialChar = /[@$!%*?&]/.test(password);

    setPasswordValidations({
      length,
      lowercase,
      uppercase,
      number,
      specialChar,
    });

    return length && lowercase && uppercase && number && specialChar;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validación de que las contraseñas coinciden
    if (newPassword !== confPassword) {
      setErrorMessage("Las contraseñas no coinciden");
      setShowErrorMessage(true);
      setShowSuccessMessage(false);
      setTimeout(() => setShowErrorMessage(false), 3000);
      return;
    }

    // Validación de la contraseña
    if (!validatePassword(newPassword)) {
      setErrorMessage(
        "La contraseña debe tener al menos 8 caracteres, incluir mayúsculas, minúsculas, números y caracteres especiales."
      );
      setShowErrorMessage(true);
      setShowSuccessMessage(false);
      setTimeout(() => setShowErrorMessage(false), 3000);
      return;
    }

    try {
      const response = await axios.post("/login/ActualizarContrasena", {
        token,
        nuevaContrasena: newPassword,
      });
      //console.log(response);
      if (response.status === 200) {
        setShowSuccessMessage(true);
        setShowErrorMessage(false);
        setErrorMessage(""); // Limpiar mensaje de error

        // Esperar 3 segundos antes de ocultar el mensaje y redirigir
        setTimeout(() => {
          setShowSuccessMessage(false);
          navigate("/"); // Redirección al login después de ocultar el mensaje
        }, 3000);
      } else {
        setShowSuccessMessage(false);
        setShowErrorMessage(true);
        setErrorMessage(
          response.data.message || "Error al actualizar la contraseña"
        );
        setTimeout(() => setShowErrorMessage(false), 3000);
      }
    } catch (error) {
      setShowSuccessMessage(false);
      setShowErrorMessage(true);
      setErrorMessage(
        error.response?.data.message || "Error al actualizar la contraseña"
      );
      setTimeout(() => setShowErrorMessage(false), 3000);
    }
  };

  return (
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img src={Logo2} className="img-fluid" alt="Logo" />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <div className="card p-4 shadow-sm">
              <div className="d-flex align-items-center mb-4">
                <Tooltip title="Regresar" arrow>
                  <IconButton onClick={() => navigate("/")}>
                    <ArrowBackIosIcon />
                  </IconButton>
                </Tooltip>
                <h2 className="ms-3 mb-0">Actualizar Contraseña</h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3 position-relative">
                  <label htmlFor="newPassword">Nueva Contraseña</label>
                  <div className="d-flex align-items-center">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="newPassword"
                      className="form-control"
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                        validatePassword(e.target.value);
                      }}
                      required
                    />
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      className="position-absolute end-0 me-3"
                      style={{ zIndex: 1 }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </div>
                </div>
                <div className="form-group mb-3 position-relative">
                  <label htmlFor="confirmPassword">Confirmar Contraseña</label>
                  <div className="d-flex align-items-center">
                    <input
                      type={showConfPassword ? "text" : "password"}
                      id="confirmPassword"
                      className="form-control"
                      value={confPassword}
                      onChange={(e) => setConfPassword(e.target.value)}
                      required
                    />
                    <IconButton
                      onClick={() => setShowConfPassword(!showConfPassword)}
                      className="position-absolute end-0 me-3"
                      style={{ zIndex: 1 }}
                    >
                      {showConfPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mt-3">
                  Actualizar Contraseña
                </button>
              </form>
              <div className="mt-3">
                <ul>
                  <li className="d-flex align-items-center">
                    {passwordValidations.length ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <CancelIcon color="error" />
                    )}
                    <span className="ms-2">Al menos 8 caracteres</span>
                  </li>
                  <li className="d-flex align-items-center">
                    {passwordValidations.lowercase ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <CancelIcon color="error" />
                    )}
                    <span className="ms-2">Incluir minúsculas</span>
                  </li>
                  <li className="d-flex align-items-center">
                    {passwordValidations.uppercase ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <CancelIcon color="error" />
                    )}
                    <span className="ms-2">Incluir mayúsculas</span>
                  </li>
                  <li className="d-flex align-items-center">
                    {passwordValidations.number ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <CancelIcon color="error" />
                    )}
                    <span className="ms-2">Incluir números</span>
                  </li>
                  <li className="d-flex align-items-center">
                    {passwordValidations.specialChar ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <CancelIcon color="error" />
                    )}
                    <span className="ms-2">Incluir caracteres especiales</span>
                  </li>
                </ul>
              </div>
              {showSuccessMessage && (
                <div className="alert alert-success mt-3">
                  Contraseña actualizada con éxito
                </div>
              )}
              {showErrorMessage && (
                <div className="alert alert-danger mt-3">{errorMessage}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NuevaContrasena;
