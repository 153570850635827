import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import TextInput from "../TextInput"; // Ajusta la ruta si es necesario
import TipoTramite from "../TipoTramiteSelect";
import SelectEscolaridad from "../Escolaridad";
import LocationForm from "../Muncipios";
import ProveedorSelect from "../ProveedorSelect";
import { NoteAdd as NoteAddIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@mui/material"; // Importar componentes adicionales de Material-UI

const Linea2Campos = ({ selectedServicio, clienteID, theme, EsGerente }) => {
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit, // Asegúrate de que `handleSubmit` está importado desde `useFormContext`
    getValues,
    trigger
  } = useForm();

  const [tipoTramite, setTipoTramite] = useState("");
  const [message, setMessage] = useState("");
  const [proveedor, setProveedor] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [Municipio, setMunicipio] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [escolaridad, setEscolaridad] = useState("");
  const [locationData, setLocationData] = useState({
    municipio: "",
    estado: "",
    codigoPostal: "",
    localidad: "",
  });
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery("(max-width:600px)"); // Detecta si es pantalla pequeña

  useEffect(() => {
    const fetchData = async () => {
      if (clienteID) {
        try {
          const response = await axios.get(
            `/services/BuscarLinea2/${clienteID}`,
            {
              withCredentials: true,
            }
          );

          //console.log("Linea2:", response.data);
          setTipoTramite(response.data.TipoTramite || "");
          setProveedor(response.data.ProveedorID || "");
          setLocalidad(response.data.MunicipioID || "");
          setMessage(response.data.Message || "");
          setLocalidad(response.data.LocalidadID || "");
          setMunicipio(response.data.MunicipioID || "");
          setCodigoPostal(response.data.CodigoPostal || "");
          setEscolaridad(response.data.escolaridad || "");
          setLocationData({
            estado: response.data.EstadoID || "",
            municipio: response.data.MunicipioID || "",
            codigoPostal: response.data.CodigoPostal || "",
            localidad: response.data.LocalidadID || "",
          });

          reset(response.data);
        } catch (error) {
          //console.error("Error al cargar los datos del cliente", error);
          setSubmitError("Hubo un error al cargar los datos del cliente.");
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    fetchData();
  }, [clienteID, reset]);

  useEffect(() => {
    if (selectedServicio) {
      const fieldsToReset = [
        "MontoEscrituracion",
        "cardul",
        "not",
        "direccion",
        "saldo",
        "porcentajeCobro",
        "detonacion",
        "contrasena",
        "visitaCardul",
        "inicioPromocion",
        "estado",
        "codigoPostal",
        "municipioID",
        "localidadID",
        "proveedorID",
        "tipoTramiteID",
      ]; // Lista de campos a resetear

      const resetSelective = () => {
        const resetValues = fieldsToReset.reduce((acc, field) => {
          acc[field] = "";
          return acc;
        }, {});

        reset((prevValues) => ({ ...prevValues, ...resetValues }));
      };

      resetSelective();
    }
  }, [selectedServicio, reset]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const linkTo = `/Documentos/${clienteID}/${selectedServicio}`;

  const onSubmit = async (data) => {
    console.log(data);
    setSubmitLoading(true);
    setSubmitError(null);
    //console.log("Datos a enviar", data);

    const formData = {
      ...data,
      ...locationData, // Incluye los datos de LocationForm
    };

    //console.log("Data a enviar", formData);
    try {
      const response = await axios.post(
        `/services/GuardarLinea2/${clienteID}`,
        formData,
        {
          withCredentials: true,
        }
      );
      setMessage(response.data.message);
      return response;
    } catch (error) {
      //console.error("Error al guardar los datos", error);
      setSubmitError("Hubo un error al guardar los datos.");
      setMessage("Algunos de los campos esta incorrecto o hace falta");
    } finally {
      setSubmitLoading(false);
    }
  };

  const customSubmit = async () => {
    const isValid = await trigger(); // Validar todos los campos manualmente

    if (!isValid) {
      // Si no es válido, hacer algo antes de que no se ejecute el submit
      const errorMessage = `¡Error! Algunos campos están vacíos o son incorrectos:
      • Antiguedad
      • Tipo de Tramite
      • Proveedor
      • Escolaridad
      • Estado
      • Codigo Postal
      • Localidad
    Por favor, verifica estos campos e intenta nuevamente.
    `;
    alert(errorMessage);
      return; // No ejecutamos handleSubmit si la validación falla
    }

    // Si la validación pasa, ejecutar handleSubmit
    handleSubmit(onSubmit)();
  };


  const handleLocationChange = (data) => {
    setLocationData(data); // Actualiza el estado con los valores de LocationForm
  };

  const handleClick = async () => {
    try {
      const formData = getValues(); // Obtén los valores del formulario
      const result = await onSubmit(formData); // Ejecuta el submit con los valores del formulario
      console.log("Respuesta: 2", result);

      // Verifica si el resultado es exitoso con un código HTTP 2xx
      if (result && result.status >= 200 && result.status < 300) {
        console.log("Fue exitoso");
        // Redirigir al enlace deseado
        navigate(linkTo);
      } else {
        console.error("Estado HTTP inesperado:", result?.status);
        const errorMessage = `¡Error! Algunos campos están vacíos o son incorrectos:
          • Antiguedad
          • Tipo de Tramite
          • Proveedor
          • Escolaridad
          • Estado
          • Codigo Postal
          • Localidad
        Por favor, verifica estos campos e intenta nuevamente.
        `;
        alert(errorMessage);

        // Manejo adicional si es necesario
      }
    } catch (error) {
      // Manejo de errores generales
      if (error.response) {
        const { status } = error.response;
        if (status === 400) {
          console.error("Error 400: Solicitud incorrecta", error.response.data);
        } else if (status === 404) {
          console.error(
            "Error 404: Recurso no encontrado",
            error.response.data
          );
        } else {
          console.error(`Error ${status}:`, error.response.data);
        }
      } else {
        // Si el error es de red o algún fallo inesperado
        console.error(
          "Error de red o fallo de conexión:",
          error.message || error
        );
      }
    }
  };
  // Determina el tipo de mensaje a mostrar
  const severity = submitError ? "error" : message ? "success" : "";

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={200}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
   
      <Box padding={3}>
        <hr style={{ margin: "20px 0", border: "2px solid #17202a" }} />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <h1>Datos de Línea 2</h1>
          <Box>
            <Tooltip title="Subir documentos">
              <IconButton
                size="large" // Tamaño grande
                style={{
                  color: "#1976d2", // Cambia el color del ícono si es necesario
                  backgroundColor: "#e3f2fd", // Color de fondo para hacerlo más visible
                  borderRadius: "50%", // Forma circular para mayor impacto visual
                  padding: "16px", // Espaciado adicional para que el ícono sea más grande
                }}
                onClick={handleClick} // Ejecuta la función al hacer clic
              >
                <NoteAddIcon style={{ fontSize: 40 }} />{" "}
                {/* Ajusta el tamaño del ícono aquí */}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <form onSubmit={(e) => e.preventDefault()} disabled>
          {/* Primera fila: Monto, CARDUL, NOT */}
          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"} // Cambia la dirección en pantallas pequeñas
          >
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="Celular"
                label="Celular"
                register={register}
                color={theme.palette.background.default}
                type="tel" // Cambié el tipo a "tel" para un mejor comportamiento en móviles
                validation={{
                  pattern: {
                    value: /^\d{10}$/, // Allows exactly 10 digits
                    message:
                      "Solo puede contener numeros y un total de 10 digitos",
                  },
                }}
                errors={errors}
                maxLength={10}
              />
            </Box>

            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="CorreoElectronico"
                label="Correo Electrónico"
                register={register}
                color={theme.palette.background.default}
                type="email" // Cambié el tipo a "email" para validación automática del navegador
                validation={{
                  required: "El correo electrónico es obligatorio.", // Validación requerida
                  pattern: {
                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, // Validación básica de formato de correo electrónico
                    message: "El correo electrónico debe ser válido.",
                  },
                }}
                errors={errors}
              />
            </Box>

            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="PuestoEmpresa"
                label="Puesto en empresa"
                register={register}
                color={theme.palette.background.default}
                type="text" // Mantener el tipo como "text" para el campo de puesto en empresa
                validation={{
                  required: "El puesto en empresa es obligatorio.", // Validación requerida
                  pattern: {
                    value: /^[a-zA-Z\s]+$/, // Validación de formato de puesto en empresa
                    message:
                      "El puesto en empresa debe ser una cadena de caracteres alfabéticos.",
                  },
                }}
                errors={errors}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"} // Cambia la dirección en pantallas pequeñas
          >
            <Box flex={1}>
              <TextInput
                required={true} // Este campo es obligatorio
                id="Antiguedad"
                label="Antigüedad"
                register={register}
                color={theme.palette.background.default}
                pattern={{
                  value: /^[0-9]*$/, // Solo permite números
                  message: "La antigüedad debe ser un número entero.",
                }}
                maxLength={5}
                minLength={1}
                errors={errors}
              />
            </Box>

            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="Area"
                label="Área donde trabaja"
                register={register}
                color={theme.palette.background.default}
                type="text" // Mantener el tipo "text" para el área
                validation={{
                  pattern: {
                    value: /^[a-zA-Z\s]+$/, // Validación de formato de área
                    message:
                      "El área debe ser una cadena de caracteres alfabéticos.",
                  },
                }}
                errors={errors}
              />
            </Box>

            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="Horario"
                label="Horario de contacto"
                register={register}
                color={theme.palette.background.default}
                type="text" // Mantener el tipo "text" para el horario
                validation={{
                  pattern: {
                    value: /^[a-zA-Z\s]+$/, // Validación de formato de horario
                    message:
                      "El horario debe ser una cadena de caracteres alfabéticos.",
                  },
                }}
                errors={errors}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"} // Cambia la dirección en pantallas pequeñas
          >
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="MontoEscrituracion"
                label="Monto de Escrituración"
                register={register}
                color={theme.palette.background.default}
                type="number"
                validation={{
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                    message: "El monto debe ser un número válido.",
                  },
                }}
                errors={errors}
              />
            </Box>
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="CARDULPrecio"
                label="CARDUL"
                register={register}
                color={theme.palette.background.default}
                maxLength={50}
                errors={errors}
              />
            </Box>
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="Notario"
                label="Notificacion"
                register={register}
                color={theme.palette.background.default}
                maxLength={50}
                errors={errors}
              />
            </Box>
          </Box>

          {/* Segunda fila: Selects (Tipo de Trámite, Localidad) */}
          <Box display="flex" gap={2} mb={2} flexDirection="row">
            <Box flex={1}>
              <TipoTramite
                register={register}
                color={theme.palette.background.default}
                required={true}
                Letras={"#000000"}
                errors={errors}
                Nombre="tipoTramite" // Nombre del campo para el registro y error
                TipoTramite={tipoTramite}
              />
            </Box>

            <Box flex={1}>
              <ProveedorSelect
                required={true}
                selectedServicio={selectedServicio}
                Proveedor={proveedor}
                color={theme.palette.background.default}
                Letras={"#000000"}
              />
            </Box>
            <Box flex={1}>
              <SelectEscolaridad
                required={true}
                escolaridadID={escolaridad} // Cambié el prop a "escolaridadID" para ser coherente
                theme={theme}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <LocationForm
                required={true}
                Gerente={EsGerente}
                initialCodigoPostal={codigoPostal}
                initialMunicipioID={Municipio}
                initialLocalidadID={localidad}
                theme={theme}
                onLocationChange={handleLocationChange}
              />
            </Box>
          </Box>
          {/* Tercera fila: Dirección, Saldo, % Cobro */}
          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="Direccion"
                label="Dirección"
                register={register}
                color={theme.palette.background.default}
                maxLength={100}
                errors={errors}
              />
            </Box>
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="Saldo"
                label="Saldo"
                register={register}
                color={theme.palette.background.default}
                type="number"
                validation={{
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                    message: "El saldo debe ser un número válido.",
                  },
                }}
                errors={errors}
              />
            </Box>
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="PorcentajeCobro"
                label="% Cobro"
                register={register}
                color={theme.palette.background.default}
                validation={{
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                    message: "El porcentaje debe ser un número válido.",
                  },
                }}
                errors={errors}
              />
            </Box>
          </Box>

          {/* Cuarta fila: Detonación, Contraseña */}
          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="Detonacion"
                label="Detonación"
                register={register}
                color={theme.palette.background.default}
                maxLength={50}
                errors={errors}
              />
            </Box>
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="Usuario"
                label="Usuario"
                register={register}
                color={theme.palette.background.default}
                type="text"
                maxLength={50}
                errors={errors}
              />
            </Box>
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="Contrasena"
                label="Contraseña"
                register={register}
                color={theme.palette.background.default}
                type="text"
                maxLength={50}
                errors={errors}
              />
            </Box>
          </Box>

          {/* Quinta fila: Campos de Fecha (Visita CARDUL, Inicio de Promoción) */}
          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="VisitaCardul"
                label="Visita CARDUL"
                register={register}
                color={theme.palette.background.default}
                type="date"
                errors={errors}
              />
            </Box>
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="InicioPromocion"
                label="Inicio de Promoción"
                register={register}
                color={theme.palette.background.default}
                type="date"
                errors={errors}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="DireccionPropiedad"
                label="Direccion de Propiedad"
                register={register}
                multiline
                rows={4}
                color={theme.palette.background.default}
                errors={errors}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <h3>Referencia 1</h3>
            </Box>
          </Box>
          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="TelefonoRef1"
                label="Telefono"
                register={register}
                color={theme.palette.background.default}
                type="tel" // Cambié el tipo a "tel" para un mejor comportamiento en móviles
                validation={{
                  pattern: {
                    value: /^[0-9]$/, // Validar números con punto decimal
                    message: "Solo puede contener numeros",
                  },
                }}
                maxLength={10}
                errors={errors}
              />
            </Box>
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="CelularRef1"
                label="Celular"
                register={register}
                color={theme.palette.background.default}
                type="tel" // Cambié el tipo a "tel" para un mejor comportamiento en móviles
                validation={{
                  pattern: {
                    value: /^[0-9]$/, // Validar números con punto decimal
                    message: "Solo puede contener numeros",
                  },
                }}
                maxLength={10}
                errors={errors}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="DireccionRef1"
                label="Dirección"
                register={register}
                color={theme.palette.background.default}
                maxLength={100}
                errors={errors}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <h3>Referencia 2</h3>
            </Box>
          </Box>
          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="TelefonoRef2"
                label="Telefono"
                register={register}
                color={theme.palette.background.default}
                type="tel" // Cambié el tipo a "tel" para un mejor comportamiento en móviles
                validation={{
                  pattern: {
                    value: /^[0-9]$/, // Validar números con punto decimal
                    message: "Solo puede contener numeros",
                  },
                }}
                maxLength={10}
                errors={errors}
              />
            </Box>
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="CelularRef2"
                label="Celular"
                register={register}
                color={theme.palette.background.default}
                type="tel" // Cambié el tipo a "tel" para un mejor comportamiento en móviles
                validation={{
                  pattern: {
                    value: /^[0-9]$/, // Validar números con punto decimal
                    message: "Solo puede contener numeros",
                  },
                }}
                maxLength={10}
                errors={errors}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="DireccionRef2"
                label="Dirección"
                register={register}
                color={theme.palette.background.default}
                maxLength={100}
                errors={errors}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <h3>Datos de empresa</h3>
            </Box>
          </Box>
          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="RazonSocial"
                label="Razón Social"
                register={register}
                color={theme.palette.background.default}
                maxLength={50}
                errors={errors}
              />
            </Box>
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="RFCEmpresa"
                label="RFC"
                register={register}
                color={theme.palette.background.default}
                type="text"
                maxLength={13}
                errors={errors}
              />
            </Box>
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="NRP"
                label="NRP"
                register={register}
                color={theme.palette.background.default}
                type="text"
                maxLength={13}
                errors={errors}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="TelefonoEmpresa"
                label="Telefono de la empresa"
                register={register}
                color={theme.palette.background.default}
                validation={{
                  pattern: {
                    value: /^[0-9]$/, // Validar números con punto decimal
                    message: "Solo puede contener numeros",
                  },
                }}
                maxLength={10}
                errors={errors}
              />
            </Box>
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="EncargadoRH"
                label="Encargado RH"
                register={register}
                color={theme.palette.background.default}
                type="text"
                maxLength={13}
                errors={errors}
              />
            </Box>
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="TelefonoRH"
                label="Telefono de encargado"
                register={register}
                color={theme.palette.background.default}
                type="text"
                validation={{
                  pattern: {
                    value: /^[0-9]$/, // Validar números con punto decimal
                    message: "Solo puede contener numeros",
                  },
                }}
                maxLength={10}
                errors={errors}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            gap={2}
            mb={2}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="CorreoRH"
                label="Correo de encargado RH"
                register={register}
                color={theme.palette.background.default}
                validation={{
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // Validar números con punto decimal
                    message: "No es un correo valido",
                  },
                }}
                maxLength={50}
                errors={errors}
              />
            </Box>

            <Box flex={1}>
              <TextInput
                Gerente={EsGerente}
                id="DireccionEmpresa"
                label="Dirección"
                register={register}
                color={theme.palette.background.default}
                maxLength={100}
                errors={errors}
              />
            </Box>
          </Box>

          {/* Botón de Enviar */}
          <Box textAlign="center" mt={3}>
          <Button
            type="button"
            variant="contained"
            onClick={customSubmit}
            style={{
              backgroundColor: theme.palette.secondary.main,
              color: "#ffffff",
            }}
            fullWidth={isSmallScreen}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Guardar Datos de Linea2"
            )}
          </Button>
          </Box>

          {/* Mensaje de Error */}
          {submitError && (
            <Box textAlign="center" mt={3}>
              <p style={{ color: "red" }}>{submitError}</p>
            </Box>
          )}

          {/* Mensaje de Éxito */}
          {message && (
            <div className={`alert alert-${severity}`} role="alert">
              {message}
            </div>
          )}
        </form>
      </Box>
  
  );
};

export default Linea2Campos;
