import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Tooltip from "@mui/material/Tooltip";
import { Link, useParams, useNavigate } from "react-router-dom";
import Menu from "../components/Menu";

import ServiciosSimple from "../components/ServiciosSimple";
import { useForm, FormProvider } from "react-hook-form";
import axios from "axios";
import TextInput from "../components/TextInput";
import InfonavitCampos from "../components/Servicios/Infonavit";
import EmbarazadasCampos from "../components/Servicios/Embarazadas";
import Linea2Campos from "../components/Servicios/Linea2";
import MejoravitCampos from "../components/Servicios/Mejoravit";
import { teal } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const themes = {
  Prospecto: createTheme({
    palette: {
      primary: {
        main: teal[600],
      },
      background: {
        default: teal[100],
      },
      text: {
        primary: "#ffffff",
      },
      secondary: {
        main: "#89000e", // Añadir color para los botones
      },
    },
  }),
  Mejoravit: createTheme({
    palette: {
      primary: {
        main: "#FB8C00", // Naranja cálido
      },
      background: {
        default: "#FFF3E0", // Naranja muy claro para los fondos de inputs
      },
      text: {
        primary: "#fff", // Casi negro para asegurar contraste
      },
      secondary: {
        main: "#1976D2", // Azul profundo para botones
      },
    },
  }),
  Linea2: createTheme({
    palette: {
      primary: {
        main: "#673AB7", // Morado mediano y elegante
      },
      background: {
        default: "#EDE7F6", // Lavanda grisáceo claro para el fondo de inputs
      },
      text: {
        primary: "#fff", // Azul muy oscuro para asegurar contraste y legibilidad
      },
      secondary: {
        main: "#2196F3", // Azul brillante para los botones
      },
    },
  }),
  Infonavit: createTheme({
    palette: {
      primary: {
        main: "#5C6BC0", // Índigo suave
      },
      background: {
        default: "#E8EAF6", // Índigo pálido para los fondos
      },
      text: {
        primary: "#FFFFFF", // Blanco para los textos
      },
      secondary: {
        main: "#D32F2F", // Rojo más suave para los botones
      },
    },
  }),
  Embarazadas: createTheme({
    palette: {
      primary: {
        main: "#EC407A", // Rosa suave
      },
      background: {
        default: "#FCE4EC", // Rosa claro para los fondos
      },
      text: {
        primary: "#FFFFFF", // Blanco para los textos
      },
      secondary: {
        main: "#004D40", // Verde oscuro para los botones
      },
    },
  }),
  // Puedes agregar más temas aquí si es necesario
};

const NuevoCliente = () => {
  const { ClienteID, ServicioID } = useParams();
  const methods = useForm({ mode: "onBlur" });
  const secondFormMethods = useForm({ mode: "onBlur" });
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = methods;
  const [selectedServicio, setSelectedServicio] = useState(ServicioID || "");
  const [message, setMessage] = useState("");

  const [severity, setSeverity] = useState("info");
  const [showSelect, setShowSelect] = useState(false);
  const [enableSelect, setEnableSelect] = useState(true);
  const [gerente, setGerente] = useState(true);
  const isEditing = Boolean(ClienteID);
  const navigate = useNavigate();

  // Wrap handleServicioChange in useCallback
  const handleServicioChange = useCallback((service) => {
    //console.log("Handling Service Change:", service); // Debugging log
    setSelectedServicio(Number(service)); // Asegúrate de que el valor sea un número
    setEnableSelect(false); // Inhabilita select tras selección
  }, []);

  useEffect(() => {
    if (isEditing) {
      const fetchClientData = async () => {
        try {
          const response = await axios.get(`/clientes/clientes/${ClienteID}`);
          const clientData = response.data;

          // Configurar los valores en el formulario
          setValue("nombres", clientData.Nombres);
          setValue("apellidoPaterno", clientData.ApellidoPaterno);
          setValue("apellidoMaterno", clientData.ApellidoMaterno);
          setValue("nss", clientData.NSS);
          setValue("curp", clientData.CURP);
          setValue("telefono", clientData.Telefono);
          setValue("comentarios", clientData.Comentarios || "");

          // Establecer selectedServicio y ejecutar handleServicioChange
          const servicioID = clientData.ServicioID || ServicioID;
          setSelectedServicio(servicioID);
          handleServicioChange(servicioID); // Llama a handleServicioChange aquí

          setShowSelect(true);
          setEnableSelect(false); // Inhabilitar select al editar
        } catch (error) {
          //console.error("Error al cargar los datos del cliente:", error);
          setMessage("Error al cargar la información del cliente.");
          setSeverity("error");
        }
      };

      fetchClientData();
    }
  }, [ClienteID, isEditing, setValue, ServicioID, handleServicioChange]);

  useEffect(() => {
    const fetchGerente = async () => {
      try {
        const response = await axios.get("/Select/Gerente", {
          withCredentials: true,
        });
        //console.log("Es gerente", response.data.hasGerenteService); // Handle the response data here
        if(response.data.hasGerenteService === 1){
          setGerente(true); // Set the fetched data to the state variable
        }else{
          setGerente(false); // Set the fetched data to the state variable
        }
       
      } catch (error) {
        //console.error("Error fetching Gerente:", error);
      }
    };

    fetchGerente();
  }, []); // Empty dependency array means this runs once when the component mounts

  useEffect(() => {
    if (selectedServicio === 1) {
      setEnableSelect(true);
    }
  }, [selectedServicio]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  // Define el tema basado en el servicio seleccionado
  const getTheme = (selectedServicio) => {
    switch (selectedServicio) {
      case 1:
        return themes.Mejoravit;
      case 2:
        return themes.Infonavit;
      case 3:
        return themes.Linea2;
      case 4:
        return themes.Embarazadas;
      default:
        return themes.Prospecto;
    }
  };

  const [theme, setTheme] = useState(getTheme(selectedServicio));

  useEffect(() => {
    // Actualiza el tema basado en el servicio seleccionado
    setTheme(getTheme(selectedServicio));
  }, [selectedServicio]);

  const handleCancel = () => {
    setSelectedServicio(""); // Limpia el valor seleccionado en el select de servicios
    setEnableSelect(true); // Habilita el select de servicios
  };

  const onSubmit = async (data) => {
    try {
      // Campos generales que deseas separar
      const camposGenerales = [
        "nombres",
        "apellidoPaterno",
        "apellidoMaterno",
        "nss",
        "curp",
        "telefono",
        "comentarios",
      ];

      // Filtrar los datos generales usando reduce sobre los campos generales
      const datosGenerales = camposGenerales.reduce((acc, field) => {
        if (data[field]) acc[field] = data[field];
        return acc;
      }, {});

      // Filtrar los datos específicos, eliminando las propiedades que están en los datos generales
      const datosEspecificos = Object.keys(data).reduce((acc, field) => {
        if (!camposGenerales.includes(field)) acc[field] = data[field];
        return acc;
      }, {});

      console.log("Cambios Generales: ", datosGenerales);
      console.log("Cambios en el Servicio: ", datosEspecificos);

      // Realizar la solicitud al servidor
      const url = isEditing
        ? `/clientes/ModificarCliente/${ClienteID}`
        : "/clientes/NuevoCliente";

      const response = await axios.post(url, datosGenerales, {
        withCredentials: true,
      });

      if (response.data?.success) {
        setMessage(response.data.message);
        setSeverity("success");

        if (!isEditing) {
          setShowSelect(false);
          setTimeout(() => {
            navigate("/Clientes");
          }, 3000);
        } else {
          setShowSelect(true);
        }
      } else {
        setMessage(response.data.message || "Error al guardar el cliente.");
        setSeverity("error");
        setShowSelect(false);
      }
    } catch (error) {
      console.log("error", error);
      setMessage(
        error.response?.data?.message ||
          "Hubo un error al enviar el formulario."
      );
      setSeverity("error");
      setShowSelect(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <FormProvider {...methods}>
        <div>
          <Helmet>
            <title>{isEditing ? "Modificar Cliente" : "Nuevo Cliente"}</title>
          </Helmet>
          <header>
            <Menu />
          </header>
          <div className="container-wrapper">
            <div className="container nuevo-usuario-container" style={{ maxWidth: "80%" }}>
              <div
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.text.primary,
                  fontWeight: "bold",
                  marginBottom: "20px",
                  padding: "20px",
                  borderRadius: "8px",
                }}
              >
                <h1>
                  <Tooltip title="Regresar">
                    <Link to="/Clientes">
                      <ArrowBackIosIcon />
                    </Link>
                  </Tooltip>
                  {isEditing ? "Modificar Cliente" : "Nuevo Cliente"}
                </h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-4">
                      <TextInput
                        id="nombres"
                        label="Nombre(s)"
                        register={register}
                        required
                        color={theme.palette.background.default}
                        pattern={{
                          value: /^[A-Za-zÁáÉéÍíÓóÚúÑñÜü\s]+$/,
                          message: "El nombre solo puede contener letras y acentos.",
                        }}
                        errors={errors}
                        onBlur={() => trigger("nombres")}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextInput
                        id="apellidoPaterno"
                        label="Apellido Paterno"
                        register={register}
                        required
                        color={theme.palette.background.default}
                        pattern={{
                          value: /^[A-Za-zÁáÉéÍíÓóÚúÑñÜü\s]+$/,
                          message: "El apellido solo puede contener letras y acentos.",
                        }}
                        errors={errors}
                        onBlur={() => trigger("apellidoPaterno")}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextInput
                        id="apellidoMaterno"
                        label="Apellido Materno"
                        register={register}
                        required
                        color={theme.palette.background.default}
                        pattern={{
                          value: /^[A-Za-zÁáÉéÍíÓóÚúÑñÜü\s]+$/,
                          message: "El apellido solo puede contener letras y acentos.",
                        }}
                        errors={errors}
                        onBlur={() => trigger("apellidoMaterno")}
                      />
                    </div>
                  </div>
  
                  <div className="row">
                    <div className="col-md-4">
                      <TextInput
                        id="nss"
                        label="NSS"
                        register={register}
                        required
                        color={theme.palette.background.default}
                        maxLength={11}
                        errors={errors}
                        onBlur={() => trigger("nss")}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextInput
                        id="curp"
                        label="CURP"
                        register={register}
                        required
                        color={theme.palette.background.default}
                        pattern={/^[A-Z0-9]{18}$/}
                        maxLength={18}
                        errors={errors}
                        onBlur={() => trigger("curp")}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextInput
                        id="telefono"
                        label="Teléfono"
                        register={register}
                        required
                        color={theme.palette.background.default}
                        pattern={/^[0-9]+$/}
                        maxLength={10}
                        errors={errors}
                        onBlur={() => trigger("telefono")}
                      />
                    </div>
                  </div>
  
                  <div className="row">
                    <div className="col-md-12">
                      <TextInput
                        id="comentarios"
                        label="Comentarios"
                        register={register}
                        multiline
                        color={theme.palette.background.default}
                        rows={4}
                        errors={errors}
                        onBlur={() => trigger("comentarios")}
                      />
                    </div>
                  </div>
  
                  <div className="row">
                    <div className="col-md-4">
                      {showSelect && (
                        <ServiciosSimple
                          color={theme.palette.background.default}
                          Letras={"#000000"}
                          onChange={handleServicioChange}
                          enableSelect={enableSelect}
                          value={selectedServicio === 6 ? "" : selectedServicio} // Pass selected value here
                        />
                      )}
                    </div>
                  </div>
  
                  <div className="col-md-12 text-center mt-4">
                    <button
                      style={{
                        backgroundColor: theme.palette.secondary.main,
                        color: "#ffffff",
                      }}
                      type="submit"
                      className="btn"
                    >
                      {isEditing ? "Modificar Datos Generales de Cliente" : "Guardar Prospecto"}
                    </button>
  
                    {selectedServicio && (
                      <button
                        type="button"
                        className="btn btn-secondary ms-2"
                        onClick={handleCancel}
                      >
                        Cancelar
                      </button>
                    )}
                  </div>
  
                  {message && (
                    <div className={`alert alert-${severity}`} role="alert">
                      {message}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        </FormProvider>
        <FormProvider {...secondFormMethods}>
        <div className="container nuevo-usuario-container" style={{ maxWidth: "80%" }}>
        <div className="row" style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.text.primary,
          fontWeight: "bold",
          padding: "20px",
          borderRadius: "8px",
          marginTop: "20px",
        }}>
          <div className="col-md-12">
            {selectedServicio === 1 && (
              <MejoravitCampos
                selectedServicio={selectedServicio}
                clienteID={ClienteID}
                theme={theme}
              />
            )}
            {selectedServicio === 2 && (
              <InfonavitCampos
                selectedServicio={selectedServicio}
                clienteID={ClienteID}
                theme={theme}
                EsGerente={gerente}
              />
            )}
            {selectedServicio === 3 && (
              <Linea2Campos
                selectedServicio={selectedServicio}
                clienteID={ClienteID}
                theme={theme}
                EsGerente={gerente}
              />
            )}
            {selectedServicio === 4 && (
              <EmbarazadasCampos
                selectedServicio={selectedServicio}
                clienteID={ClienteID}
                theme={theme}
                EsGerente={gerente}
              />
            )}
          </div>
        </div>
        </div>
        </FormProvider>
    </ThemeProvider>
  );
  
  
};

export default NuevoCliente;
