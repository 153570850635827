import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const LocalidadSelect = ({
  Gerente,
  Nombre,
  errors,
  register,
  Localidad,
  color,
  Letras,
  required,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    const fetchLocalidades = async () => {
      try {
        const response = await axios.get("/clientes/localidad");
        setOptions(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchLocalidades();
  }, []);

  return (
    <FormControl
      fullWidth
      variant="filled"
      margin="normal"
      error={!!(errors && errors[Nombre])} // Verificar si hay error en el campo
    >
      <InputLabel
        style={{ color: Letras, fontSize: 20 }}
        id={`${Nombre}-label`}
      >
        Entidad {required ? "*" : ""}
      </InputLabel>
      {loading ? (
        <CircularProgress size={24} />
      ) : error ? (
        <FormHelperText>
          Hubo un error al cargar las localidades.
        </FormHelperText>
      ) : (
        <Select
          {...register(Nombre, {
            required: required ? "Este campo es obligatorio" : false, // Validación para requerido
          })}
          defaultValue={Localidad || ""}
          readOnly={Gerente}
          style={{
            background: color || theme.palette.background.default,
            color: "#000000",
          }}
          labelId={`${Nombre}-label`}
          id={Nombre}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: color || theme.palette.background.default,
              },
            },
          }}
          renderValue={(selected) => {
            const selectedOption = options.find(
              (option) => option.ArticuloID === selected
            );
            return (
              <span style={{ color: "#000000" }}>
                {selectedOption ? selectedOption.Nombre : "Seleccione una localidad"}
              </span>
            );
          }}
        >
          <MenuItem value="" disabled style={{ color: "#000000" }}>
            <em>Seleccione una localidad</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem
              key={option.ArticuloID}
              value={option.ArticuloID}
              style={{ color: "#000000" }}
            >
              {option.Nombre}
            </MenuItem>
          ))}
        </Select>
      )}

      {/* Mostrar el mensaje de error con el ícono de advertencia y color blanco */}
      <FormHelperText 
        style={{ 
          display: 'flex', 
          alignItems: 'center', 
          color: 'white', // Establecer el color blanco para el mensaje de error
        }}
      >
        {errors && errors[Nombre] && (
          <>
            <IconButton style={{ padding: 0, marginRight: 5 }}>
              <ErrorOutlineIcon style={{ color: '#ff0000' }} />
            </IconButton>
            {errors[Nombre]?.message}
          </>
        )}
        {error || ""}
      </FormHelperText>
    </FormControl>
  );
};

export default LocalidadSelect;
