import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const TipoTramite = ({ Gerente, register, errors, Nombre, TipoTramite, required }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme(); // Obtener el tema

  useEffect(() => {
    const fetchTipoTramite = async () => {
      try {
        const response = await axios.get("/clientes/tipo-tramite"); // Ajusta el endpoint según sea necesario
        setOptions(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTipoTramite();
  }, []);

  return (
    <FormControl
      fullWidth
      variant="filled"
      margin="normal"
      error={!!(errors && errors[Nombre])}
    >
      <InputLabel id="tipo-tramite-label" style={{ color: "#000000" }}>
        Tipo de Trámite *
      </InputLabel>
      {loading ? (
        <CircularProgress size={24} />
      ) : error ? (
        <FormHelperText>Hubo un error al cargar los datos.</FormHelperText>
      ) : (
        <Select
          required={required}
          readOnly={Gerente}
          labelId="tipo-tramite-label"
          id="tipo-tramite"
          {...register(Nombre, { required: "Este campo es requerido" })}
          defaultValue={TipoTramite || ""}
          style={{
            background: theme.palette.background.default, // Usa el color de fondo del tema
            color: "#000000", // Color del texto
          }}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: theme.palette.background.default, // Fondo del menú desplegable
              },
            },
          }}
          renderValue={(selected) => (
            <span style={{ color: "#000000" }}>
              {selected === ""
                ? "Seleccione"
                : options.find((option) => option.ArticuloID === selected)
                    ?.Nombre}
            </span>
          )}
        >
          <MenuItem value="" style={{ color: "#000000" }} disabled>
            Seleccione
          </MenuItem>
          {options.map((option) => (
            <MenuItem
              key={option.ArticuloID}
              value={option.ArticuloID}
              style={{ color: "#000000" }}
            >
              {option.Nombre}
            </MenuItem>
          ))}
        </Select>
      )}
      {/* Muestra el mensaje de error con el ícono si hay error */}
      {errors && errors[Nombre] && (
        <div style={{ display: "flex", alignItems: "center", color: "white" }}>
          <IconButton style={{ padding: 0, marginRight: 5 }}>
            <ErrorOutlineIcon style={{ color: "red" }} />
          </IconButton>
          <span>{errors[Nombre].message}</span>
        </div>
      )}
      <FormHelperText>
      </FormHelperText>
    </FormControl>
  );
};

export default TipoTramite;
