import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import Confirmacion from "../Usuarios/Confirmacion";
import Modificacion from "../Usuarios/Modificacion";

export default function TablaUsuarios() {
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModificar, setShowModificar] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("/usuarios/Usuarios");
      setRows(response.data || []);
    } catch (error) {
      //console.error("Error fetching data:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleShowAllChange = (event) => {
    setShowAll(event.target.checked);
  };

  const handleExpandRow = (row) => {
    setExpandedRow(expandedRow === row ? null : row);
  };

  const handleDeleteClick = (row) => {
    setModalData(row);
    setShowConfirmationModal(true);
  };

  const handleModificarClick = (row) => {
    setModalData(row);
    setShowModificar(true);
  };

  const filteredRows = rows.filter((row) => {
    const matchesSearch =
      (row.Nombres &&
        row.Nombres.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.ApellidoPaterno &&
        row.ApellidoPaterno.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.ApellidoMaterno &&
        row.ApellidoMaterno.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.Correo &&
        row.Correo.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.Catalogo &&
        row.Catalogo.Nombre.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.Permisos &&
        row.Permisos.toLowerCase().includes(searchTerm.toLowerCase()));

    if (showAll) {
      return matchesSearch;
    } else {
      return row.Catalogo && row.Catalogo.Nombre === "ACTIVO" && matchesSearch;
    }
  });

  return (
    <div>
      <div className="bg-beige container-wrapper">
        <div style={{ paddingBottom: "10px", display: "flex", alignItems: "center" }}>
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              width: "200px",
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginRight: "10px",
            }}
          />
          <Button
            component={Link}
            to="/NuevoUsuario"
            variant="contained"
            color="primary"
          >
            Nuevo
          </Button>
          <div style={{ marginLeft: "10px" }}>
            <input
              type="checkbox"
              checked={showAll}
              onChange={handleShowAllChange}
            />
            <label style={{ marginLeft: "5px" }}>
              Mostrar todos los usuarios
            </label>
          </div>
        </div>
        <Grid container spacing={2}>
          {filteredRows.map((row, index) => (
            <Grid item key={row.AsesorID} xs={12} sm={6} md={4}>
              <Card>
                <CardContent>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                      {row.Nombres} {row.ApellidoPaterno}
                    </Typography>
                    {row.Catalogo && row.Catalogo.Nombre === "ACTIVO" && (
                      <>
                        <Tooltip title="Editar">
                          <IconButton
                            color="primary"
                            aria-label="edit"
                            onClick={() => handleModificarClick(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Borrar">
                          <IconButton
                            color="error"
                            aria-label="delete"
                            onClick={() => handleDeleteClick(row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </div>
                  <Typography variant="body2" color="textSecondary">
                    Correo: {row.Correo}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                        Telefono: {row.Telefono} 
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Status: {row.Catalogo ? row.Catalogo.Nombre : ""}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Permisos: {row.Permisos === "0" ? "ASESOR" : "ADMINISTRADOR"}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Oficina: {row.Oficina.Nombre}
                  </Typography>
                  {expandedRow === row && (
                    <div style={{ marginTop: "10px" }}>
                      <Typography variant="subtitle1">Servicios:</Typography>
                      <ul>
                        {row.AsesorServicios.map((servicio) => (
                          <li key={servicio.id}>{servicio.Servicio.Nombre}</li>
                        ))}
                      </ul>
                      <Typography variant="body2" color="textSecondary">
                        Modificado por: {row.AsesorModifica?.Nombres} {row.AsesorModifica?.ApellidoPaterno}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Oficina: {row.Oficina.Nombres}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Creado el: {new Date(row.createdAt).toLocaleString()}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Actualizado el: {new Date(row.updatedAt).toLocaleString()}
                      </Typography>
                      <IconButton
                        onClick={() => handleExpandRow(null)}
                        aria-label="Mostrar menos"
                      >
                        <ExpandLessIcon />
                      </IconButton>
                    </div>
                  )}
                  {expandedRow !== row && (
                    <IconButton
                      onClick={() => handleExpandRow(row)}
                      aria-label="Mostrar más"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Confirmacion
          open={showConfirmationModal}
          onClose={() => {
            setShowConfirmationModal(false);
            fetchData();
          }}
          modalData={modalData}
        />
        <Modificacion
          open={showModificar}
          onClose={() => {
            setShowModificar(false);
            fetchData();
          }}
          modalData={modalData}
          setModalData={setModalData}
        />
      </div>
    </div>
  );
}
