import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import axios from "axios";

//console.log(process.env.REACT_APP_API_URL); // Verifica que esta línea imprima la URL correcta

axios.defaults.baseURL = process.env.REACT_APP_API_URL;


const root = document.getElementById("root");
const rootElement = createRoot(root);

rootElement.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
