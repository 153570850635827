import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  ModalDialog,
} from "@mui/joy";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import axios from "axios";

export default function Confirmacion({ open, onClose, data }) {
  // Elimina el token como prop
  const [mensaje, setMensaje] = useState("");
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");

  useEffect(() => {
    //console.log("La información recibida para el modal es:", data);

    if (data) {
      if (data.StatusID === 2) {
        setMensaje("¿Quieres reactivar la oficina?");
      } else if (data.StatusID === 1) {
        setMensaje("¿Quieres dar de baja la oficina?");
      } else {
        setMensaje("Estado desconocido.");
      }
    }
  }, [data]);

  const showMessage = (msg, color = "green") => {
    setMessage(msg);
    setMessageColor(color);
    setTimeout(() => {
      setMessage("");
      if (color === "green") {
        onClose(); // Cerrar el modal después de 3 segundos si es éxito
      }
    }, 3000); // Mostrar el mensaje durante 3 segundos
  };

  const handleConfirm = async () => {
    //console.log("Enviando datos al backend...", data);

    try {
      const response = await axios.post(
        `/oficinas/BorrarOficinas/${data.OficinaID}`, // Remueve el ID relacionado al token
        data, // Manda solo el cuerpo de la data
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      //console.log("Respuesta del backend:", response.data);

      if (response.status === 200 || response.status === 201) {
        showMessage(
          <span>
            <CheckCircleIcon /> {response.data.message || "Operación exitosa"}
          </span>,
          "green"
        );
      } else {
        showMessage(
          <span>
            <WarningIcon /> {response.data.error || "Error en la operación"}
          </span>,
          "red"
        );
      }
    } catch (error) {
      //console.error("Error al enviar datos:", error);
      showMessage(
        <span>
          <WarningIcon />{" "}
          {error.response?.data?.error || "Error en la operación"}
        </span>,
        "red"
      );
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          <WarningRoundedIcon />
          Confirmación
        </DialogTitle>
        <Divider />
        <DialogContent>
          {mensaje && <p>{mensaje}</p>}
          {message && <span style={{ color: messageColor }}>{message}</span>}
        </DialogContent>
        <DialogActions>
          <Button variant="solid" color="danger" onClick={handleConfirm}>
            Confirmar
          </Button>
          <Button variant="plain" color="neutral" onClick={onClose}>
            Cancelar
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
}
