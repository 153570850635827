import React, { useEffect, useState, useCallback } from "react";
import { Pie } from "react-chartjs-2";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import axios from "axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Registra los elementos que vas a utilizar
ChartJS.register(ArcElement, Tooltip, Legend);

const EmbarazadasDetenidos = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [isLoading, setIsLoading] = useState(true); // Estado para manejar el estado de carga
  const [error, setError] = useState(null); // Estado para manejar errores

  // Crear un arreglo de 20 colores diferentes

  // Función para obtener datos del backend
  const fetchData = useCallback(async () => {
    try {
      const colores = [
        "#FF5733",
        "#33FF57",
        "#5733FF",
        "#FF33A8",
        "#33C4FF",
        "#FF8333",
        "#FF3385",
        "#33FFB8",
        "#B833FF",
        "#FF3333",
        "#FFBD33",
        "#33FF90",
        "#5B33FF",
        "#FF6F33",
        "#7AFF33",
        "#33FF65",
        "#9933FF",
        "#FF7F33",
        "#FFC733",
        "#6B33FF",
      ];

      const response = await axios.get("/KPI/EmbarazadasDetenidos", {
        withCredentials: true,
      });
      console.log(response); // Puedes eliminar esto una vez que todo esté funcionando
      const data = response.data;

      // Aquí se asume que la respuesta tiene los campos "oficina" y "mejoravitsCount"
      const labels = data.map((item) => item.oficina);
      const values = data.map((item) => item.EmbarazadasCount);

      // Asignar colores de manera cíclica
      const assignedColors = labels.map(
        (_, index) => colores[index % colores.length]
      );

      setChartData({
        labels: labels,
        datasets: [
          {
            label: "Embarazadas Detenidos",
            data: values,
            backgroundColor: assignedColors, // Asignar los colores aquí
          },
        ],
      });
    } catch (error) {
      setError("Error al cargar los datos.");
    } finally {
      setIsLoading(false);
    }
  }, []); // Usamos useCallback para evitar que 'fetchData' cambie entre renders

  useEffect(() => {
    fetchData();
  }, [fetchData]); // Aquí ahora no es necesario agregar 'fetchData' como dependencia adicional

  // Calcular si no hay datos para mostrar el mensaje
  const totalCantidad =
    chartData.datasets.length > 0
      ? chartData.datasets[0].data.reduce((acc, val) => acc + val, 0)
      : 0;

  return (
    <Card className="chart-card h-100" elevation={3}>
      <CardContent>
        <Typography variant="h6" align="center">
          Embarazadas Detenidos por oficina
        </Typography>
        {isLoading ? ( // Muestra el indicador de carga si está cargando
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : error ? ( // Muestra el mensaje de error si ocurre un error
          <Typography color="error" align="center">
            {error}
          </Typography>
        ) : totalCantidad === 0 ? ( // Verifica si no hay datos
          <Typography
            variant="h5"
            align="center"
            color="error"
            style={{ margin: "20px 0", fontWeight: "bold" }}
          >
            No hay servicios en proceso
          </Typography>
        ) : (
          <div style={{ height: "300px" }}>
            <Pie
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default EmbarazadasDetenidos;
