import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  FormHelperText,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { Controller, useFormContext } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ProveedorSelect = ({
  Gerente,
  selectedServicio,
  Proveedor,
  required,
}) => {
  const [proveedores, setProveedores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState("");

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const theme = useTheme(); // Obtener el tema

  useEffect(() => {
    const fetchProveedores = async () => {
      try {
        setLoading(true); // Asegurarse de que el estado de carga se restablezca al cambiar el servicio
        setError(""); // Limpiar errores previos

        const response = await axios.get("/clientes/Proveedores", {
          params: { servicio: selectedServicio },
        });

        if (response.data) {
          setProveedores(response.data);
        } else {
          setProveedores([]);
          setError("No se encontraron proveedores para este servicio.");
        }
      } catch (err) {
        setError("Error al cargar proveedores.");
      } finally {
        setLoading(false);
      }
    };

    if (selectedServicio) {
      fetchProveedores();
    } else {
      setProveedores([]);
      setLoading(false);
    }
  }, [selectedServicio]);

  return (
    <FormControl
      fullWidth
      variant="filled"
      margin="normal"
      error={Boolean(errors.proveedor)}
    >
      <InputLabel id="proveedor-label" style={{ color: "#000000" }}>
        Proveedor{required ? " *" : ""}
      </InputLabel>

      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <Controller
          name="proveedor"
          control={control}
          defaultValue={Proveedor === 0 || Proveedor === "" ?  "" : Proveedor}

          rules={{ required: required ? "Este campo es requerido" : false }}
          render={({ field }) => (
            <Select
              required={required}
              {...field}
              readOnly={Gerente}
              labelId="proveedor-label"
              id="proveedor"
              style={{
                background: theme.palette.background.default,
                color: "#000000",
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: theme.palette.background.default,
                  },
                },
              }}
              renderValue={(selected) => (
                <span style={{ color: "#000000" }}>
                  {selected === ""
                    ? "Seleccione un proveedor"
                    : proveedores.find(
                        (proveedor) => proveedor.ProveedorID === selected
                      )?.Nombre || "Proveedor desconocido"}
                </span>
              )}
            >
              <MenuItem value="" disabled style={{ color: "#000000" }}>
                Seleccione un proveedor
              </MenuItem>
              {proveedores.map((proveedor) => (
                <MenuItem
                  key={proveedor.ProveedorID}
                  value={proveedor.ProveedorID}
                  style={{ color: "#000000" }}
                >
                  {proveedor.Nombre}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      )}

      {/* Muestra el ícono de error y el mensaje debajo si hay un error */}
      {errors.proveedor && (
        <div style={{ display: "flex", alignItems: "center", color: "white" }}>
          <IconButton style={{ padding: 0, marginRight: 5 }}>
            <ErrorOutlineIcon style={{ color: "red" }} />
          </IconButton>
          <span>{errors.proveedor.message}</span>
        </div>
      )}

      <FormHelperText>
      </FormHelperText>
    </FormControl>
  );
};

export default ProveedorSelect;
