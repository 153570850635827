import { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal, Box, Typography, Button } from "@mui/material";

const SessionManager = () => {
  const navigate = useNavigate();
  const inactivityTimeout = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal de inactividad
  const [isSessionClosedModalOpen, setIsSessionClosedModalOpen] = useState(false); // Modal de sesión cerrada
  const [timeLeft, setTimeLeft] = useState(30); // Estado para el contador
  const INACTIVITY_LIMIT = 60 * 60 * 1000; 
  const TOKEN_CHECK_INTERVAL = 5 * 60 * 1000; 

  // Validar token
  const validateToken = useCallback(async () => {
    try {
      const response = await axios.post("/auth/validate-token", {}, { withCredentials: true });
      //console.log("Validando token");
      if (!response.data.isValid) {
        alert("Sesión expirada. Redirigiendo...");
        navigate("/"); // Redirigir a login
      }
    } catch (error) {
      //console.error("Error al validar el token:", error);
      alert("Error al validar sesión. Redirigiendo...");
      navigate("/"); // Redirigir a login
    }
  }, [navigate]);

  // Reiniciar el temporizador de inactividad
  const resetInactivityTimer = useCallback(() => {
    clearTimeout(inactivityTimeout.current);
    setIsModalOpen(false); // Cerrar modal si la actividad se detecta

    inactivityTimeout.current = setTimeout(() => {
      setIsModalOpen(true); // Abrir el modal 30 segundos antes de la expiración
    }, INACTIVITY_LIMIT - 30000); // 30 segundos antes del límite de inactividad

    // Reiniciar el contador cuando se muestra el modal
    setTimeLeft(30);
  }, [INACTIVITY_LIMIT]);

  // Control del contador
  useEffect(() => {
    let countdownInterval;
    if (isModalOpen && timeLeft > 0) {
      countdownInterval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }

    // Cuando el contador llegue a cero, cerrar la sesión
    if (timeLeft === 0) {
      clearInterval(countdownInterval);
      setIsModalOpen(false); // Cerrar el modal de inactividad
      setIsSessionClosedModalOpen(true); // Mostrar el modal de sesión cerrada
    }

    return () => clearInterval(countdownInterval); // Limpiar el intervalo al desmontar
  }, [isModalOpen, timeLeft]);

  useEffect(() => {
    // Escuchar eventos de actividad del usuario
    const events = ["keydown", "click", "scroll"];
    events.forEach((event) => window.addEventListener(event, resetInactivityTimer));

    // Configurar intervalo para la validación del token
    const tokenValidationInterval = setInterval(validateToken, TOKEN_CHECK_INTERVAL);

    // Iniciar el temporizador de inactividad
    resetInactivityTimer();

    return () => {
      events.forEach((event) => window.removeEventListener(event, resetInactivityTimer));
      clearTimeout(inactivityTimeout.current);
      clearInterval(tokenValidationInterval);
    };
  }, [resetInactivityTimer, validateToken, TOKEN_CHECK_INTERVAL]);

  // Modal para mostrar la cuenta regresiva
  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate("/"); // Redirigir a login si el usuario cierra el modal
  };

  // Modal para mostrar que la sesión fue cerrada por inactividad
  const handleSessionClosedModalClose = () => {
    setIsSessionClosedModalOpen(false);
    navigate("/"); // Redirigir al login después de que el usuario cierre este modal
  };

  return (
    <>
      {/* Modal de inactividad */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: 4,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <Typography variant="h6">Tu sesión se cerrará en:</Typography>
          <Typography variant="h4">{timeLeft} segundos</Typography>
          <Button variant="contained" color="primary" onClick={handleCloseModal} sx={{ marginTop: 2 }}>
            Cancelar
          </Button>
        </Box>
      </Modal>

      {/* Modal de sesión cerrada por inactividad */}
      <Modal open={isSessionClosedModalOpen} onClose={handleSessionClosedModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: 4,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <Typography variant="h6">Tu sesión ha sido cerrada por inactividad.</Typography>
          <Button variant="contained" color="primary" onClick={handleSessionClosedModalClose} sx={{ marginTop: 2 }}>
            OK
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default SessionManager;
