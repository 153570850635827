import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import "./Loginstyles.css";
import Logo2 from "./GrupoSirLogo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showInvalidDataMessage, setShowInvalidDataMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [, setCookie] = useCookies(["token"]);
  const [, removeCookie] = useCookies(["token"]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {
    const logout = async () => {
      try {
        await axios.post("/login/logout", {}, { withCredentials: true });
        removeCookie("token", { path: "/" }); // Elimina la cookie
        console.log("Cookie 'token' eliminada");
      } catch (error) {
        // Manejo de error si es necesario
        console.error('Error al cerrar sesión:', error.response ? error.response.data : error.message);
      }
    };
    logout();
  }, [removeCookie]); // Solo se ejecuta cuando se monta el componente

  const onSubmit = async (data) => {
    try {
      const response = await axios.post("/login", data, {
        withCredentials: true,
      });

      if (response.status === 200) {
        const { Ingreso, token } = response.data;

        if (Ingreso === "1" && token) {
          setCookie("token", token, {
            path: "/",
            expires: new Date(Date.now() + 2 * 60 * 60 * 1000),
            sameSite: "strict",
          });
          navigate("/Dashboard");
        } else if (Ingreso === "0") {
          navigate(`/NuevaContrasena/${token}`);
        } else {
          setErrorMessage("Estado no manejado");
          setShowInvalidDataMessage(true);
        }
      } else {
        setErrorMessage("Código de estado inesperado");
        setShowInvalidDataMessage(true); 
      }
    } catch (error) {
      const mensajeError =
        error.response?.data?.message || "Error al enviar los datos";
      setErrorMessage(mensajeError);
      setShowInvalidDataMessage(true);
    }
  };

  const handleForgotPassword = () => {
    navigate("/BuscarAsesor");
  };

  return (
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img src={Logo2} className="img-fluid" alt="Imagen" />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <div className="card shadow-lg p-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start mb-4">
                  <p className="lead fw-normal mb-0 me-3">Iniciar sesión</p>
                </div>
                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="email">
                    Correo
                  </label>
                  <input
                    type="email"
                    id="email"
                    className={`form-control form-control-lg ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    placeholder="ejemplo@gmail.com"
                    {...register("email", {
                      required: "El correo es obligatorio",
                    })}
                  />
                  {errors.email && (
                    <div className="invalid-feedback">
                      {errors.email.message}
                    </div>
                  )}
                </div>
                <div className="form-outline mb-3">
                  <TextField
                    id="password"
                    label="Contraseña"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ""}
                    {...register("password", {
                      required: "La contraseña es obligatoria",
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={handleForgotPassword}
                  >
                    ¿Olvidaste la contraseña?
                  </button>
                </div>
                <div className="text-center text-lg-start mt-4 pt-2">
                  <button type="submit" className="btn btn-primary btn-lg">
                    Iniciar sesión
                  </button>
                </div>
              </form>
              {showInvalidDataMessage && (
                <div className="alert alert-danger mt-3" role="alert">
                  {errorMessage || "Usuario y/o contraseña incorrecto"}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
