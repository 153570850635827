import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { AccountCircle, Work } from '@mui/icons-material';
import Menu from "../../components/Menu";
import TablaUsuarios from "./tablasAdmin/Usuarios/Usuarios";
import TablasOficinas from "./tablasAdmin/Oficinas/Oficinas"; // Importa tu componente TablasOficinas

export default function TabsComponent() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
    
      <header>
        <Menu />
      </header>
      <div className="bg-beige container-wrapper">
        <div>
          <Tabs value={value} onChange={handleChange} centered>
          <Tab icon={<AccountCircle />} label="USUARIOS" />
            <Tab icon={<Work />} label="Oficinas" />
          </Tabs>
          <div>
            {value === 0 && <TablaUsuarios />}
            {value === 1 && <TablasOficinas />}
          </div>
        </div>
      </div>
    </div>
  );
}
