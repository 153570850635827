import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem, 
  FormControl,
  InputLabel,
  CircularProgress,
  FormHelperText,
  
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import axios from "axios";

const WPIGFBSelect = ({ Gerente, wpigfb,required }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext(); // Accede al contexto del formulario
  const [WPIGFB, setWPIGFB] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const theme = useTheme(); // Obtener el tema

  useEffect(() => {
    const fetchWPIGFB = async () => {
      try {
        const response = await axios.get("/clientes/WPIGFB");

        if (response.data) {
          setWPIGFB(response.data);
          //console.log("Los WPIGFB:", response.data);
        } else {
          setError("No se encontraron datos.");
        }
      } catch (err) {
        //console.error("Error fetching WPIGFB:", err);
        setError("Error al cargar WPIGFB.");
      } finally {
        setLoading(false);
      }
    };

    fetchWPIGFB();
  }, []);

  return (
    <FormControl
      fullWidth
      variant="filled"
      margin="normal"
      error={Boolean(errors.WPIGFB) || Boolean(error)}
    >
      <InputLabel id="wpigfb-label" style={{ color: "#000000" }}>
        Redes *
      </InputLabel>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <Controller
          name="WPIGFB" // Nombre del campo en el formulario
          control={control}
          defaultValue={wpigfb || ""} // Valor predeterminado para el controlador
          render={({ field }) => (
            <Select
            required={required}
              readOnly={Gerente}
              {...field}
              labelId="wpigfb-label"
              id="wpigfb"
              label="Redes"
              style={{
                background: theme.palette.background.default, // Color de fondo del Select
                color: "#000000", // Color del texto
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: theme.palette.background.default, // Fondo del menú desplegable
                  },
                },
              }}
              renderValue={(selected) => (
                <span style={{ color: "#000000" }}>
                  {selected === ""
                    ? "Seleccione una opción"
                    : WPIGFB.find((item) => item.ID === selected)?.Nombre}
                </span>
              )}
            >
              <MenuItem value="" style={{ color: "#000000" }} disabled>
                Seleccione una opción
              </MenuItem>
              {WPIGFB.length ? (
                WPIGFB.map((item) => (
                  <MenuItem
                    key={item.ID}
                    value={item.ID}
                    style={{ color: "#000000" }}
                  >
                    {item.Nombre}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled style={{ color: "#000000" }}>
                  No hay opciones disponibles
                </MenuItem>
              )}
            </Select>
          )}
        />
      )}
      <FormHelperText>{errors.WPIGFB?.message || ""}</FormHelperText>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default WPIGFBSelect;
