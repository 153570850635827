import React, { useState, useEffect } from "react";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const SelectOficinas = ({ onChange, selectedValue }) => {
  const [oficinas, setOficinas] = useState([]);

  useEffect(() => {
    const fetchOficinas = async () => {
      try {
        const response = await axios.get("/oficinas/Oficinas");
        if (response.status !== 200) {
          throw new Error("Error al obtener las oficinas");
        }
        setOficinas(response.data);
      } catch (error) {
        //console.error("Hubo un error:", error);
      }
    };
    fetchOficinas();
  }, []);

  // Validar si el valor seleccionado está dentro de las opciones disponibles
  const validSelectedValue = oficinas.some(
    (oficina) => oficina.OficinaID === selectedValue
  )
    ? selectedValue
    : "";

  return (
    <FormControl fullWidth variant="outlined" margin="normal">
      <InputLabel id="selectOficina-label">Oficina</InputLabel>
      <Select
        labelId="selectOficina-label"
        id="selectOficina"
        value={validSelectedValue}
        onChange={onChange}
        label="Oficina"
        required
        sx={{
          backgroundColor: "white",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "blue",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "blue",
          },
          ".MuiSvgIcon-root": {
            color: "black",
          },
        }}
      >
        <MenuItem value="">
          <em>Seleccione una opción</em>
        </MenuItem>
        {oficinas.map((oficina) => (
          <MenuItem key={oficina.OficinaID} value={oficina.OficinaID}>
            {oficina.Nombre}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectOficinas;
