import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SelectOficinas from "../../../../components/Oficinas";
import SelectServicios from "../../../../components/Servicios";
import { useForm } from "react-hook-form";
import axios from "axios";

export default function Modificacion({
  open,
  onClose,
  modalData,
  setModalData,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur", // Activa las validaciones al salir del campo
  });

  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("green");
  const [showForm, setShowForm] = useState(true);
  const [showButtons, setShowButtons] = useState(true);
  const [selectedOficina, setSelectedOficina] = useState("");
  const [selectedServicios, setSelectedServicios] = useState([]);

  useEffect(() => {
    if (open && modalData) {
      // Resetear los estados para permitir nuevas modificaciones
      console.log(modalData);
      setShowForm(true);
      setShowButtons(true);
      setMessage("");

      //console.log("La data es", modalData);
      setValue("Nombres", modalData.Nombres || "");
      setValue("ApellidoPaterno", modalData.ApellidoPaterno || "");
      setValue("ApellidoMaterno", modalData.ApellidoMaterno || "");
      setValue("OficinaID", modalData.Oficina.OficinaID || "");
      setValue("Telefono", modalData.Telefono || "");

      const servicios = Array.isArray(modalData.AsesorServicios)
        ? modalData.AsesorServicios.map((servicio) => servicio.ServicioID)
        : [];
      setSelectedOficina(modalData.Oficina.OficinaID || "");
      setSelectedServicios(servicios);
    }
  }, [open, modalData, setValue]);
 
  const onSubmit = async (data) => {
    try {
      if (!modalData || !modalData.AsesorServicios) {
        throw new Error("Datos de AsesorServicios no disponibles");
      }

      data.AsesorID = modalData.AsesorID;
      data.OficinaID = selectedOficina;

      const response = await axios.post("/usuarios/Modificar", data, {
        withCredentials: true,
      });

      if (response.status === 200 || response.status === 201) {
        const responseData = response.data;

        showMessage(
          <span>
            <CheckCircleIcon />{" "}
            {responseData.message || "Usuario modificado correctamente"}
          </span>,
          "green"
        );

        //console.log("Respuesta del servidor:", responseData);

        if (
          responseData.AsesorServicios &&
          responseData.AsesorServicios.Servicio
        ) {
          const updatedServicios = responseData.AsesorServicios.Servicio.map(
            (servicio) => servicio.ServicioID
          );
          setSelectedServicios(updatedServicios);

          const newData = {
            ...modalData,
            AsesorServicios: responseData.AsesorServicios,
          };
          setModalData(newData);
        } else {
          /*console.warn(
            "La estructura de AsesorServicios en la respuesta no es la esperada:",
            responseData.AsesorServicios
          );*/
        }

        setShowForm(false);
        setShowButtons(false);

        setTimeout(() => {
          onClose();
        }, 3000);
      } else {
        showMessage(
          <span>
            <WarningIcon />{" "}
            {response.data.error || "Error al modificar el usuario"}
          </span>,
          "red"
        );
      }
    } catch (error) {
      //console.error("Error al enviar datos:", error);
      showMessage(
        <span>
          <WarningIcon /> {error.message || "Error al enviar los datos"}
        </span>,
        "red"
      );
    }
  };

  const showMessage = (msg, color = "green") => {
    setMessage(msg);
    setMessageColor(color);
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const handleOficinaChange = (event) => {
    setSelectedOficina(event.target.value);
  };

  const handleServiciosChange = (selected) => {
    setSelectedServicios(selected);
  };

  if (!modalData) {
    return null;
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        style={{ marginTop: 20 }}
      >
        <DialogTitle>
          <ManageAccountsIcon />
          Modificación de Usuario
        </DialogTitle>
        <DialogContent sx={{ marginTop: 2 }}>
          {showForm ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Nombres"
                    fullWidth
                    required
                    {...register("Nombres", {
                      required: "Este campo es requerido",
                      pattern: {
                        value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s-]+$/, // Solo letras, espacios y guiones
                        message: "Solo se permiten letras, espacios y guiones",
                      },
                    })}
                    error={!!errors.Nombres}
                    helperText={errors.Nombres ? errors.Nombres.message : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Apellido Paterno"
                    fullWidth
                    required
                    {...register("ApellidoPaterno", {
                      required: "Este campo es requerido",
                      pattern: {
                        value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s-]+$/, // Solo letras, espacios y guiones
                        message: "Solo se permiten letras, espacios y guiones",
                      },
                    })}
                    error={!!errors.ApellidoPaterno}
                    helperText={
                      errors.ApellidoPaterno
                        ? errors.ApellidoPaterno.message
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Apellido Materno"
                    fullWidth
                    required
                    {...register("ApellidoMaterno", {
                      required: "Este campo es requerido",
                      pattern: {
                        value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s-]+$/, // Solo letras, espacios y guiones
                        message: "Solo se permiten letras, espacios y guiones",
                      },
                    })}
                    error={!!errors.ApellidoMaterno}
                    helperText={
                      errors.ApellidoMaterno
                        ? errors.ApellidoMaterno.message
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Telefono"
                    fullWidth
                    required
                    {...register("Telefono", {
                      required: "Este campo es requerido",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "El teléfono debe contener solo 10 dígitos",
                      },
                      maxLength: {
                        value: 10,
                        message:
                          "El teléfono no puede tener más de 10 caracteres",
                      },
                    })}
                    error={!!errors.Telefono}
                    helperText={errors.Telefono ? errors.Telefono.message : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectOficinas
                    selectedValue={selectedOficina}
                    onChange={handleOficinaChange}
                    required
                    register={register}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <SelectServicios
                    selectedValues={selectedServicios}
                    onChange={handleServiciosChange}
                    required
                    register={register}
                  />
                </Grid>
              </Grid>
              <DialogActions>
                {showButtons && (
                  <>
                    <Button onClick={onClose}>Cancelar</Button>
                    <Button type="submit" variant="contained" color="primary">
                      Guardar
                    </Button>
                  </>
                )}
              </DialogActions>
              {message && (
                <div style={{ color: messageColor, marginTop: "10px" }}>
                  {message}
                </div>
              )}
            </form>
          ) : (
            <div style={{ textAlign: "center" }}>
              <p style={{ color: "green" }}>
                {message || "Usuario modificado correctamente"}
              </p>
              <Button onClick={onClose} variant="contained" color="primary">
                Ok
              </Button>
            </div>
          )}
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
}
