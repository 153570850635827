import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const SelectEscolaridad = ({ Gerente, escolaridadID, required }) => {
  const [escolaridades, setEscolaridades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState("");

  const {
    control,
    formState: { errors },
    setValue, // to manually trigger onBlur validation
  } = useFormContext();
  const theme = useTheme(); // Obtener el tema

  useEffect(() => {
    // Obtener las escolaridades desde el backend
    const fetchEscolaridades = async () => {
      try {
        setLoading(true); // Asegurarse de que el estado de carga se reinicia
        setError(""); // Limpiar errores anteriores

        const response = await axios.get("/Select/escolaridades"); // Ruta de la API
        if (response.data) {
          setEscolaridades(response.data);
        } else {
          setEscolaridades([]);
          setError("No se encontraron escolaridades disponibles.");
        }
      } catch (err) {
        setError("Error al cargar escolaridades."); // Establecer mensaje de error
      } finally {
        setLoading(false); // Finalizar el estado de carga
      }
    };

    fetchEscolaridades(); // Llamar a la función cuando se monte el componente
  }, []);

  return (
    <FormControl
      fullWidth
      variant="filled"
      margin="normal"
      error={Boolean(errors.escolaridad)}
    >
      <InputLabel id="escolaridad-label" style={{ color: "#000000" }}>
        Escolaridad{required ? " *" : ""}
      </InputLabel>

      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <Controller
          name="escolaridad"
          control={control}
          defaultValue={escolaridadID || ""}
          rules={{
            required: required ? "Este campo es requerido." : false,
          }}
          render={({ field }) => (
            <Select
              {...field}
              readOnly={Gerente}
              labelId="escolaridad-label"
              id="escolaridad"
              style={{
                background: theme.palette.background.default, // Color de fondo
                color: "#000000", // Color del texto
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: theme.palette.background.default, // Fondo del menú desplegable
                  },
                },
              }}
              renderValue={(selected) => (
                <span style={{ color: "#000000" }}>
                  {selected === ""
                    ? "Seleccione una opción"
                    : escolaridades.find(
                        (escolaridad) => escolaridad.ArticuloID === selected
                      )?.Nombre || "Escolaridad desconocida"}
                </span>
              )}
              onBlur={() => setValue("escolaridad", field.value, { shouldValidate: true })} // Trigger onBlur validation
            >
              <MenuItem value="" disabled style={{ color: "#000000" }}>
                Seleccione una opción
              </MenuItem>
              {escolaridades.length > 0 ? (
                escolaridades.map((escolaridad) => (
                  <MenuItem
                    key={escolaridad.ArticuloID}
                    value={escolaridad.ArticuloID}
                    style={{ color: "#000000" }}
                  >
                    {escolaridad.Nombre}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled style={{ color: "#000000" }}>
                  No hay escolaridades disponibles
                </MenuItem>
              )}
            </Select>
          )}
        />
      )}

      {/* Muestra el ícono de error y el mensaje debajo si hay un error */}
      {errors.escolaridad && (
        <div style={{ display: "flex", alignItems: "center", color: "white" }}>
          <IconButton style={{ padding: 0, marginRight: 5 }}>
            <ErrorOutlineIcon style={{ color: "red" }} />
          </IconButton>
          <span>{errors.escolaridad.message}</span>
        </div>
      )}

      <FormHelperText>
       
      </FormHelperText>
    </FormControl>
  );
};

export default SelectEscolaridad;
