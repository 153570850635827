import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { Grid, Checkbox, FormControlLabel, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import Menu from "../../../../components/Menu";
import Campos from "../../../../components/CamposTexto";
import SelectOficinas from "../../../../components/Oficinas";
import SelectServicios from "../../../../components/Servicios";
import "../Usuarios/NuevoUsuarioStyles.css"; // Asegúrate de que el archivo CSS esté importado correctamente

const NuevoUsuario = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({ mode: "onBlur", criteriaMode: "all" });

  const [selectedOficina, setSelectedOficina] = useState("");
  const [selectedServicios, setSelectedServicios] = useState([]);
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");

  // Función para manejar la presentación del mensaje
  const showMessage = (msg, color = "green") => {
    setMessage(msg);
    setMessageColor(color);
    setTimeout(() => {
      setMessage("");
    }, 3000); // Mostrar el mensaje durante 3 segundos
  };

  const handleOficinaChange = (event) => {
    const value = event.target.value;
    setSelectedOficina(value);
    setValue("OficinaID", value); // Aseguramos que el valor del formulario se actualiza
  };

  const handleServiciosChange = (selected) => {
    setSelectedServicios(selected);
    setValue("Servicios", selected); // Actualizar el valor del formulario
  };

  const messages = {
    req: "Este campo es obligatorio",
    nombre:
      "El formato introducido no es el correcto, no puede contener números ni caracteres especiales",
    apellidoP:
      "El formato introducido no es el correcto, no puede contener números ni caracteres especiales",
    apellidoM:
      "El formato introducido no es el correcto, no puede contener números ni caracteres especiales",
    email: "Debes introducir una dirección correcta",
    telefono: "Solo puede contener números",
  };

  const patterns = {
    nombre: /^[A-Za-zÀ-ÿ0-9\s.,#-]+$/,
    apellidoP: /^[A-Za-zÀ-ÿ0-9\s.,#-]+$/,
    telefono: /^[0-9]+$/,
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  };

  const onSubmit = async (data) => {
    try {
      // Realizar la solicitud POST
      const response = await axios.post("/usuarios/NuevoUsuario", data, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      //console.log(response.data);

      if (response.status === 200 || response.status === 201) {
        showMessage(response.data.message, "green");
        reset(); // Resetea el formulario
        setSelectedOficina(""); // Resetea la oficina seleccionada
        setSelectedServicios([]); // Resetea los servicios seleccionados
      } else {
        showMessage(response.data.error, "red");
      }
    } catch (error) {
      //console.error("Error al enviar datos:", error);
      showMessage(
        error.response?.data?.error || "Error en la operación",
        "red"
      );
    }
  };



  return (
    <div>
      <Helmet>
        <title>Nuevo Usuario</title>
      </Helmet>
      <header>
        <Menu />
      </header>
      <div className="container-wrapper">
        <div className="container nuevo-usuario-container">
          <div className="bg-beige">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              {/* Header del Formulario */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Tooltip title="Regresar">
                    <Link to="/Usuarios">
                      <ArrowBackIosIcon />
                    </Link>
                  </Tooltip>
                  <h1>Nuevo Usuario</h1>
                </Grid>
                <Grid item xs={12}>
                  <h3>Datos generales</h3>
                </Grid>
  
                {/* Campos del Formulario */}
                <Grid item xs={12} sm={6}>
                  <Campos
                    htmlFor="email"
                    label="Correo electrónico"
                    register={register}
                    required={true}
                    pattern={patterns.email}
                    messages={messages}
                    errors={errors}
                    tipo="email"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Campos
                    htmlFor="nombre"
                    label="Nombre(s)"
                    register={register}
                    required={true}
                    pattern={patterns.nombre}
                    messages={messages}
                    errors={errors}
                    tipo="text"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Campos
                    htmlFor="apellidoP"
                    label="Apellido Paterno"
                    register={register}
                    required={true}
                    pattern={patterns.nombre}
                    messages={messages}
                    errors={errors}
                    tipo="text"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Campos
                    htmlFor="apellidoM"
                    label="Apellido Materno"
                    register={register}
                    required={true}
                    pattern={patterns.nombre}
                    messages={messages}
                    errors={errors}
                    tipo="text"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Campos
                    htmlFor="telefono"
                    label="Teléfono"
                    register={register}
                    required={true}
                    pattern={patterns.telefono}
                    messages={messages}
                    errors={errors}
                    tipo="text"
                    maxLength={10}
                    minLength={10}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectOficinas
                    onChange={handleOficinaChange}
                    selectedValue={selectedOficina}
                    register={register}
                    required={true}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="admin"
                        {...register("admin")}
                        name="admin"
                        autoComplete="off"
                      />
                    }
                    label="¿Es administrador?"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectServicios
                    onChange={handleServiciosChange}
                    selectedValues={selectedServicios}
                    register={register}
                    required={true}
                    autoComplete="off"
                  />
                </Grid>
  
                {/* Mensaje */}
                <Grid item xs={12}>
                  {message && <p style={{ color: messageColor }}>{message}</p>}
                </Grid>
  
                {/* Botón */}
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default NuevoUsuario;
