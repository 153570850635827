// App.js
import { Helmet } from "react-helmet";
import RouterConfig from "./RouterConfig";
import React, { useEffect } from "react";
import axios from "axios";

const App = () => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Realiza logout antes de cerrar la pestaña
      axios.post("/login/logout", {}, { withCredentials: true })
        .then(response => {
          if (response.status === 200) {
            console.log("Sesión cerrada correctamente.");
          }
        })
        .catch(error => {
          console.error("Error al hacer logout:", error);
        });
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Limpiar el evento al desmontar el componente
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>GrupoSIR</title>
        <link rel="icon" href="../public/favicon.ico" /> {/* Ruta al favicon */}
      </Helmet>
      <RouterConfig />
    </div>
  );
};

export default App;
