import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
  IconButton,
} from "@mui/material";
import axios from "axios";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ProgramaSelect = ({
  Gerente,
  register,
  errors,
  Nombre,
  defaultValue = "",
  color,
  Letras,
  required
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProgramas = async () => {
      try {
        const response = await axios.get("/clientes/Programas");
        setOptions(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProgramas();
  }, []);

  return (
    <FormControl
      fullWidth
      variant="filled"
      margin="normal"
      error={!!errors[Nombre]} // Highlight if error exists
    >
      <InputLabel
        style={{ color: Letras, fontSize: 20 }}
        id={`${Nombre}-label`}
      >
        Programa {required ? "*" : ""}
      </InputLabel>
      {loading ? (
        <CircularProgress size={24} />
      ) : error ? (
        <FormHelperText>Hubo un error al cargar los programas.</FormHelperText>
      ) : (
        <Select
          readOnly={Gerente}
          style={{ background: color, color: "#000000" }}
          labelId={`${Nombre}-label`}
          id={Nombre}
          defaultValue={defaultValue || ""}
          {...register(Nombre, { required: required ? "Este campo es obligatorio" : false })}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: color,
              },
            },
          }}
          renderValue={(selected) => (
            <span style={{ color: "#000000" }}>
              {options.find((option) => option.ArticuloID === selected)
                ?.Nombre || "Seleccione una opción"}
            </span>
          )}
        >
          <MenuItem style={{ color: "#000000" }} value="">
            <em>Seleccione una opción</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem
              key={option.ArticuloID}
              value={option.ArticuloID}
              style={{ color: "#000000" }}
            >
              {option.Nombre}
            </MenuItem>
          ))}
        </Select>
      )}

      {/* Mostrar el mensaje de error con el ícono de advertencia */}
      <FormHelperText style={{ display: 'flex', alignItems: 'center', color: '#ffffff' }}>
        {errors[Nombre] && (
          <>
            <IconButton style={{ padding: 0, marginRight: 5 }}>
              <ErrorOutlineIcon style={{ color: '#ff0000' }} />
            </IconButton>
            {errors[Nombre]?.message}
          </>
        )}
        {error || ""}
      </FormHelperText>
    </FormControl>
  );
};

export default ProgramaSelect;
